import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { DefaultValuesSettingsComponent } from './default-values-settings/default-values-settings.component';
import { RouterModule, Routes } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { FormsModule } from '@angular/forms';
import { PublishingCalendarComponent } from './publishing-calendar/publishing-calendar.component';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { PackageSettingModalComponent } from './publishing-calendar/package-setting-modal/package-setting-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { PuzzlesValuesViewComponent } from './publishing-calendar/package-setting-modal/puzzles-values-view/puzzles-values-view.component';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { PackgesPuzzlesConfigModalComponent } from './publishing-calendar/package-setting-modal/packges-puzzles-config-modal/packges-puzzles-config-modal.component';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { PackageAddPuzzleModalComponent } from './publishing-calendar/package-setting-modal/packges-puzzles-config-modal/package-add-puzzle-modal/package-add-puzzle-modal.component';
import { PuzzlesPublishingService } from './puzzles-publishing.service';
import { SelectPuzzlesToAddComponent } from './publishing-calendar/package-setting-modal/packges-puzzles-config-modal/package-add-puzzle-modal/select-puzzles-to-add/select-puzzles-to-add.component';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { NzInputModule } from 'ng-zorro-antd/input';
import { PackageReplacePuzzleComponent } from './publishing-calendar/package-setting-modal/packges-puzzles-config-modal/package-replace-puzzle/package-replace-puzzle.component';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PuzzlesConfigService } from 'src/app/puzzles-config.service';
import { DailyPackageConfigModule } from '../shared/daily-package-config/daily-package-config/daily-package-config.module';


const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

const routes: Routes = [
  {
    path: '', component: PageLayoutComponent
  } 
];

@NgModule({
  declarations: [
    PageLayoutComponent,
    DefaultValuesSettingsComponent,
    PublishingCalendarComponent,
    PackageSettingModalComponent,
    PuzzlesValuesViewComponent,
    PackgesPuzzlesConfigModalComponent,
    PackageAddPuzzleModalComponent,
    SelectPuzzlesToAddComponent,
    PackageReplacePuzzleComponent,
  ],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    NzTableModule,
    NzButtonModule,
    NzInputNumberModule,
    NzCalendarModule,
    NzBadgeModule,
    NzDatePickerModule,
    NzModalModule,
    NzTabsModule,
    NzIconModule,
    NzCardModule,
    NzListModule,
    NzGridModule,
    NzMentionModule,
    NzInputModule,
    NzTypographyModule,
    NzPopconfirmModule,
    DailyPackageConfigModule
  ],
  providers: [
    PuzzlesPublishingService,
    { provide: NZ_ICONS, useValue: icons },
    NzMessageService,
    PuzzlesConfigService
  ]

})
export class PuzzlesPublishingModule { }
