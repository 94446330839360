import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterprecetorService implements HttpInterceptor {


  constructor(private auth: AuthService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    return next.handle(this.getAuthorizedRequest(req))
  }

  getAuthorizedRequest(req: HttpRequest<any>) {
    if(!this.auth.getToken()) {
      this.router.navigateByUrl('/')
    }

    this.auth.getToken() ? this.auth.getToken() : ''
    
    return req.clone({
      setHeaders: {
        'x-access-token': this.auth.getToken() ? this.auth.getToken() : ''
      }
    })
  }
}

