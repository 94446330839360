import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Puzzle } from '../../puzzle-view/table-puzzle/table-puzzle.component';
import { BinPuzzleService } from '../bin-puzzle.service';

@Component({
  selector: 'app-puzzles-complains-comments',
  template: `
      <div class="row">
      <div class="col-12">
        <p>Alle Puzzle zur Ausführung:</p>
        <div style="line-break:anywhere;">
          <div *ngFor="let item of puzzlesIds; index as index">
            <h6>
              {{index}}. {{item.uuid}}<br />
            </h6>
            <p>{{item.complainComment}}</p>
          </div>
        </div>
        <button nz-button nzType="primary" (click)="sendBinPuzzlesEmail()">Rätsel reklamiere</button>
      </div>
    </div>
  `
})
export class PuzzlesComplainsCommentsComponent {


  @Input() puzzlesIds: Puzzle[] = []

  @Output() public refreshPuzzles$ = new EventEmitter()


  constructor(
    private toastr: ToastrService,
    private puzzleService: BinPuzzleService
  ) { }


  sendBinPuzzlesEmail() {

    const puzzlesIds = this.puzzlesIds.map((p, index) => {
      return p._id
    })

    if (this.puzzlesIds.length < 1) { 
      return this.toastr.error('Check at least one puzzle')
    }

    this.toastr.info('Sending email...')
    this.puzzleService.sendBinPuzzlesEmail(puzzlesIds)
      .subscribe((
        () => {
          this.toastr.success('Email sent')
          this.puzzleService.refreshPuzzleTable$.next(true)
          this.puzzlesIds = []
        }),
        () => {
          this.toastr.error("Something go wrong")
        })

  }

}
