<div class="container" style="margin-left: 30px;">
    <h1 class="header">Users</h1>
    <div class="row">
        <div class="col">
            <div class="statistic-card">
                <p>
                    All users
                </p>
                <div>2.3k</div>
            </div>
        </div>
        <div class="col">
            <div class="statistic-card">
                <p>
                    Free Users
                </p>
                <div>1.5k</div>
            </div>
        </div>
        <div class="col">
            <div class="statistic-card">
                <p>
                    Premium Users
                </p>
                <div>0.3k</div>
            </div>
        </div>
        <div class="col">
            <div class="statistic-card">
                <p>
                    Trial Users
                </p>
                <div>0.3k</div>
            </div>
        </div>
        <div class="col">
            <div class="statistic-card">
                <p>
                    Today Online
                </p>
                <div>1.0k</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-users-pie-chart></app-users-pie-chart>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-users-line-chart></app-users-line-chart>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-online-users-line-chart></app-online-users-line-chart>
        </div>
    </div>
    <h1 class="header">Puzzles</h1>
    <div class="row">
        <div class="col">
            <div class="statistic-card">
                <p>
                    Played Last Month
                </p>
                <div>2.0k</div>
            </div>
        </div>
        <div class="col">
            <div class="statistic-card">
                <p>
                    Current Month
                </p>
                <div>0.9k</div>
            </div>
        </div>
        <div class="col">
            <div class="statistic-card">
                <p>
                    Best Day
                </p>
                <div>1.0k</div>
            </div>
        </div>
        <div class="col">
            <div class="statistic-card">
                <p>
                    Worst Day
                </p>
                <div>0.1k</div>
            </div>
        </div>
    </div>
</div>
