/* declarations: NzModalCustomFooterComponent */

import { Component, Input } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PuzzlesPublishingService } from '../../../puzzles-publishing.service';
import { DailyPackages, puzzleData } from '../../publishing-calendar.component';

export interface DailyPackageConfig {
  dailyPackage: DailyPackages,
  puzzlesCategory: 'maxi' | 'mini',
  profilType: 'lite' | 'registered' | 'premium'
  puzzlesNumber?: number
}

@Component({
  selector: 'app-packges-puzzles-config-modal',
  template: `
    <app-puzzles-edit-list [dailyPackage]="source"></app-puzzles-edit-list>
  `,
  styles: [`
      nz-card {
        padding: 0px;
      }

    :host ::ng-deep nz-card > div.ant-card-body {
        padding: 0
     }

     :host ::ng-deep nz-list > nz-spin {
        background-color: rgb(41 40 40 / 9%);
        padding: 15px;
     }

     .icon-add {
        position: relative;
        top: -3px;
        left: 4px;
     }
  `]
})
export class PackgesPuzzlesConfigModalComponent {

  source: any = {} 

  @Input('dailyPackage') set dailyPackageConfig(source: DailyPackageConfig) {

    this.source = source
    // console.log(source, 'source')
    this.data = source.dailyPackage[source.profilType][source.puzzlesCategory]
  }

  data: puzzleData[] = [];

  isVisible = false;
  isConfirmLoading = false;

  constructor(private modalService: NzModalService, private service: PuzzlesPublishingService) { }

  handleOk(): void {
    this.isConfirmLoading = true;
    setTimeout(() => {
      this.isVisible = false;
      this.isConfirmLoading = false;
    }, 3000);
  }

  handleCancel(): void {
    this.isVisible = false;
  }
}
