export var onlineUsers = [
    {
      "name": "All Online Users",
      "series": [
        {
          "name": "2020.05.20",
          "value": 50
        },
        {
          "name": "2020.07.11",
          "value": 200
        },
        {
          "name": "2020.08.26",
          "value": 366
        },
        {
          "name": "2020.09.26",
          "value": 400
        },
        {
          "name": "2020.10.26",
          "value": 800
        },
        {
          "name": "2020.11.26",
          "value": 1200
        },
        {
          "name": "2021.12.26",
          "value": 1500
        },
        {
          "name": "2021.1.26",
          "value": 2000
        }
      ]
    },
  ];
  