import { Component, OnInit } from '@angular/core';
import { os } from './phone-template/phone/phone.component';

@Component({
  selector: 'app-create-notification-view',
  template: `
  <div style="display: flex; gap: 50px;"> 
    <div style="display: inline-block">
      <div style="display: inline-block">
        <app-users-segments [header]="'1. Select Users Segments'" [segments]="usersSegments"></app-users-segments>
        <app-users-segments class="mt-2" [header]="'2. Select OS'" [segments]="systemSegments"></app-users-segments>
      </div>
      <div style="display: inline-block; position: absolute; margin-left: 100px;">
        <app-notification-form></app-notification-form>
      </div>
      <app-scheduled-notification-form></app-scheduled-notification-form>
    </div>
    <div style="display: inline-block">
      <div>
        <h3 class="segment-select">5. See/Push</h3>
        <select [(ngModel)]="system" class="form-select select-custom mx-auto" aria-label="Default select example">
          <option selected disabled>Select System</option>
          <option value="ios">iOS</option>
          <option value="android">Google Android</option>
        </select>
        <app-phone [system]="system"></app-phone>
      </div>
    </div>
  </div>

  `,
  styleUrls: ['./create-notification-view.component.scss']
})
export class CreateNotificationViewComponent {

  systemSegments: os[] = ['android', 'ios']
  usersSegments = ['Free', 'Trial', 'Premium']

  system: os = 'ios'

}
