import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';
import { EditPuzzleModalComponent } from '../../puzzle-view/puzzle-edit/edit-puzzle-modal/edit-puzzle-modal.component';
import { Puzzle } from '../../puzzle-view/table-puzzle/table-puzzle.component';
import { PaginateData } from '../../users-view/users-table/users-table.component';
import { PremiumPuzzleService } from '../premium-puzzle.service';
import { CancelPuzzlePublishingConfirmModalComponent } from './cancel-puzzle-publishing-confirm-modal/cancel-puzzle-publishing-confirm-modal.component';
import { ChangePublishDateModalComponent } from './change-publish-date-modal/change-publish-date-modal.component';

export interface SelectedPuzzle {
  uuid: string
  day: string
  open: boolean
  totalGrid: number
  points: number
  _id: string
  daily: boolean,
  dailymini: boolean,
  premium: boolean | undefined,
  status: number
  complainComment?: string
  grid: any
  grid2: any
  // date?: string
  selected?: boolean
}

@Component({
  selector: 'app-premium-view',
  templateUrl: './premium-view.component.html',
  styleUrls: ['./premium-view.component.scss']
})
export class PremiumViewComponent {

  puzzles: SelectedPuzzle[] = []

  publishedPuzzles: Puzzle[][] = []

  puzzlesCount: number = 0;
  publishDate: string = '';

  puzzlesMaxiLength: number = 0
  puzzlesMiniLength: number = 0

  selectedCategory: 'MAXI' | 'MINI' = 'MAXI'

  active = 1;

  loading = true;

  @Output() onChosenPremiumPuzzle = new EventEmitter<Puzzle>()

  constructor(
    public puzzleService: PremiumPuzzleService,
    private modalService: NgbModal,
    private toastr: ToastrService

  ) {
    this.getPuzzles(this.selectedCategory)
    this.getCountedMaxiPuzzle()
    this.getCountedMiniPuzzle()
    this.getLatestPublishedPuzzles()
  }

  getLatestPublishedPuzzles() {
    this.puzzleService.getLatestPublishedPuzzles('MAXI')
      .subscribe((l => {

        const puzzles = (l as any).list as Puzzle[]
        console.log(puzzles, 'puzzles');

        const mappedPuzzles = puzzles.map(p => {

          const puzzlePublishDay = new Date(p.day)

          return { ...p, key: `${puzzlePublishDay.getFullYear()}.${puzzlePublishDay.getMonth()}.${puzzlePublishDay.getDay()}` }
        })

        const sortedPuzzlesObj = {}

        mappedPuzzles.forEach(p => {
          if (sortedPuzzlesObj[p.key]) {
            sortedPuzzlesObj[p.key].push(p)
          } else {
            sortedPuzzlesObj[p.key] = [p]
          }
        })

        console.log(sortedPuzzlesObj, 'sorted');

        this.publishedPuzzles = []
        Object.values(sortedPuzzlesObj)
          .forEach((p) => this.publishedPuzzles.push(p as Puzzle[]))

        console.log(sortedPuzzlesObj, 'sorted')

      }))
  }
  paginate: PaginateData = {
    page: 1,
    pageSize: 20
  }

  onScroll(event: any) {
    const scrolledBottom = event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight
    if (scrolledBottom) {
      console.log('true', scrolledBottom)
      this.paginate.page++;
      this.getPuzzles(this.selectedCategory)
    }
  }

  getPuzzles(category: 'MAXI' | 'MINI') {
    this.puzzleService
      .getPremiumList(this.paginate, category)
      .subscribe(el => {
        this.puzzles = [...this.puzzles, ...el]
        if (category === 'MINI') console.log(el, 'puzzlessss')
        this.loading = false;
      })
  }

  openPuzzle(editPuzzle: Puzzle) {
    this.puzzleService.getSinglePuzzle(editPuzzle._id)
      .subscribe(
        (puzzle) => {
          const puzzleModal = this.modalService.open(EditPuzzleModalComponent, {
            size: 'xl'
          })
          puzzleModal.componentInstance.editPuzzle = editPuzzle;
          puzzleModal.componentInstance.editPuzzle.points = editPuzzle.totalGrid;

          puzzleModal.componentInstance.puzzle = puzzle
          puzzleModal.componentInstance.disabled = true
        })

  }
  refreshView() {
    console.log('refresh');

    this.paginate = {
      page: 1,
      pageSize: 20
    }
    this.puzzles = []
    this.getPuzzles(this.selectedCategory)
  }

  reset() {
    this.puzzlesCount = 0
    this.publishDate = ''
    this.paginate = {
      page: 1,
      pageSize: 20
    }
    this.puzzles = []
    this.getCountedMaxiPuzzle()
    this.getCountedMiniPuzzle()
    this.getPuzzles(this.selectedCategory)
    this.getLatestPublishedPuzzles()
  }

  getCountedMaxiPuzzle() {
    this.puzzleService.getCountedPuzzles('MAXI')
      .subscribe(p => this.puzzlesMaxiLength = p.count)
  }

  getCountedMiniPuzzle() {
    this.puzzleService.getCountedPuzzles('MINI')
      .subscribe(p => this.puzzlesMiniLength = p.count)
  }

  updateSelectedPuzzles() {
    this.puzzles = this.puzzles
      .map((p, index) => {
        if (index < this.puzzlesCount) {
          return ({ ...p, selected: true })
        } else {
          return ({ ...p, selected: false })
        }
      })
  }

  changePuzzlePublishDate() {

  }

  cancelPuzzlePublishing() {

  }

  publishPuzzles() {

    console.log(this.puzzlesCount, 'ok');

    if (this.puzzlesCount < 1) {
      return this.toastr.error('You should provide at least one puzzle')
    }

    if (this.publishDate.length < 3) {
      return this.toastr.error('You should set a publish date')
    }

    console.log(this.puzzlesCount, this.publishDate, 'ok');

    let puzzlesToUpdate = [...this.puzzles.filter(p => p.selected)]

    puzzlesToUpdate = puzzlesToUpdate
      .map(p => {
        delete p.selected;
        return {
          ...p,
          premium: true,
          status: 2,
          open: true,
          day: new Date(this.publishDate) as any
        }
      })

    this.createPromiseArr(puzzlesToUpdate)

  }

  updatePuzzleCount($event: any, item: SelectedPuzzle) {
    item.selected = false;
    if ($event.target.checked) {
      item.selected = true;
    }
    this.puzzlesCount = this.puzzles.filter(p => p.selected).length
  }


  createPromiseArr(puzzles: Puzzle[]) {

    this.toastr.info('Publishing in progress...')
    const $requestUpdatePuzzleArr: Observable<any>[] = []

    puzzles.forEach((obj, index) => {
      $requestUpdatePuzzleArr.push(this.puzzleService.updatePuzzle(obj._id, obj));
    })

    forkJoin($requestUpdatePuzzleArr)
      .subscribe(r => {
        this.toastr.success('Premium puzzles published!')
        this.reset()
      })
  }

  changeCategory(category: 'MAXI' | 'MINI') {
    this.selectedCategory = category;
    this.reset()
  }

  openDeleteUserModal(puzzle: Puzzle) {
    const modal = this.modalService.open(CancelPuzzlePublishingConfirmModalComponent)
    modal.componentInstance.puzzle = puzzle
    modal.result.then(() => {
      this.toastr.info('Cancelling puzzle...')
      puzzle.status = 1
      puzzle.day = ""
      this.puzzleService.updatePuzzle(puzzle._id, puzzle)
        .subscribe(() => {
          this.toastr.success('Puzzle Cancelled')
          this.reset()
        })
    }).catch(e => e)
  }

  openChangePublishDateModal(puzzle: Puzzle) {
    const modal = this.modalService.open(ChangePublishDateModalComponent)
    modal.componentInstance.puzzle = puzzle
    modal.result.then((date) => {
      this.toastr.info('Updating Date...')
      puzzle.day = new Date(date) as any 
      this.puzzleService.updatePuzzle(puzzle._id, puzzle)
        .subscribe(() => {
          this.toastr.success('Date Updated')
          this.reset()
        })
    }).catch(e => e)
  }


}

        // console.log($event.target.checked, 'checked');
      // item.selected = false;
      // if ($event.target.checked) {
      //   item.selected = true;
      //   item.premium = true;
      //   item.status = 2;
      //   item.open = true;
      //   let found = -1;
      //   for (let i = 0; i < this.puzzlesIds.length; i++) {
      //     if (this.puzzlesIds[i]._id == item._id) found = i;
      //   }
      //   if (found > -1) return;
      //   else this.puzzlesIds.push(item)
      // } else {
      //   this.puzzlesIds = this.puzzlesIds.filter((p: any) => p._id !== item._id)
      //   console.log(this.puzzlesIds, 'return')
      // }
