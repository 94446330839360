import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Puzzle } from '../../../puzzle-view/table-puzzle/table-puzzle.component';

@Component({
  template: `
   <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Publish Date</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>Select Date for <span class="text-primary">{{puzzle.uuid | puzzlesCategory}} {{puzzle.uuid}}</span> ?</strong></p>
    <div class="form-group form-inline inline-block  mr-2">
        set publishDate: <input placeholder="label" type="date" [(ngModel)]="publishDate" >
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="confirm()">Ok</button>
  </div>
    `,
})
export class ChangePublishDateModalComponent  {
  
  publishDate: string = '';
  @Input() puzzle: Puzzle | any = {};
  

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService
    ) { }

  confirm() {

    if (this.publishDate.length < 3) {
      return this.toastr.error('You should set a publish date')
    }

    this.modal.close(this.publishDate)
  }

}
