<dl *ngIf="!loading" class="row">
    <dt class="col-sm-3">
        <button class="btn mb-2 btn-info" (click)="nextUser.emit()">Back</button>
    </dt>
    <dd class="col-sm-9">
    </dd>

    <dt class="col-sm-3">Name</dt>
    <dd class="col-sm-9">{{user?.displayName }}</dd>

    <dt class="col-sm-3">Email</dt>
    <dd class="col-sm-9">
        {{user.email}}
    </dd>

    <dt class="col-sm-3">registriert seit</dt>
    <dd class="col-sm-9">
        {{user.date | date: 'medium' }}
    </dd>

    <dt class="col-sm-3">Zuletzt online:</dt>
    <dd class="col-sm-9"> - </dd>

    <dt class="col-sm-3">Rätsel gespielt TOTAL</dt>
    <dd class="col-sm-9"> {{userStatistcs.playedPuzzlesTotal}}</dd>

    <dt class="col-sm-3">Maxi</dt>
    <dd class="col-sm-9"> {{userStatistcs?.maxiPuzzlesTotal}}</dd>

    <dt class="col-sm-3">Mini</dt>
    <dd class="col-sm-9">{{userStatistcs?.miniPuzzlesTotal}}</dd>

    <dt class="col-sm-3">letzte 7 Tage</dt>
    <dd class="col-sm-9">{{userStatistcs?.playedlastWeekTotal}}</dd>

    <dt class="col-sm-3 text-truncate">aktueller Mona</dt>
    <dd class="col-sm-9">{{userStatistcs?.playedLastMonthTotal}}</dd>

    <dt class="col-sm-3 text-truncate">Monatliche Fuchsjagd</dt>
    <dd class="col-sm-9"> {{rankFox + 1 | number:'1.0-0' }}</dd>

    <dt class="col-sm-3 text-truncate">erspielte Punkte (Bestenliste)</dt>
    <dd class="col-sm-9">{{rankAll + 1 | number:'1.0-0' }}</dd>

    <dt class="col-sm-3">
        <button type="button" ngbAutofocus ngbButton (click)="openDeleteUserModal(user)"
            class="btn btn-danger">Delete</button>
    </dt>
    <dd class="col-sm-9">
    </dd>

</dl>
<p *ngIf="loading">
    Loading...
</p>