<nz-mention #mention (nzOnSelect)="onSearchChange($event)" (nzOnSelect)="onSearchChange($event)" [nzPrefix]="'@'"
  [nzSuggestions]="puzzlesIds">
  <textarea #mention nz-input [nzAutosize]="{ minRows: 4, maxRows: 4 }" [(ngModel)]="inputValue"
    nzMentionTrigger> </textarea>
</nz-mention>
<nz-list nzGrid>
  <div nz-row [nzGutter]="16">
    <div nz-col [nzXXl]="6" [nzXl]="6" [nzLg]="6" [nzMd]="6" [nzSm]="6" [nzXs]="6" *ngFor="let item of data">
      <nz-list-item>
        <div style="background-color: white;" class="ant-card-head ng-star-inserted">
          <div class="ant-card-head-wrapper">
            <div class="ant-card-head-title ng-star-inserted">{{item}}
              <nz-button-group [nzSize]="'small'">
                <button nz-button nzType="dashed" class="btn-replace"><i nz-icon nzType="reload"></i></button>
                <button nz-button nzType="danger" class="btn-remove"><i nz-icon nzType="delete"></i></button>
              </nz-button-group>
            </div>
          </div>
        </div>
      </nz-list-item>
    </div>
  </div>
</nz-list>
<!-- <nz-modal [(nzVisible)]="isVisible" nzTitle="Custom Modal Title" (nzOnCancel)="handleCancel()">
  <div *nzModalContent>
    <p>Modal Content</p>
    <p>Modal Content</p>
    <p>Modal Content</p>
    <p>Modal Content</p>
    <p>Modal Content</p>
  </div>
  <div *nzModalFooter>
    <span>Modal Footer:</span>
    <button nz-button nzType="default" (click)="handleCancel()">Cancell</button>
    <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isConfirmLoading">Ok</button>
  </div>
</nz-modal> -->