import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface puzzlesAmount {
  maxi: number
  mini: number
}

export interface PuzzlesPublishingConfig {
  lite: puzzlesAmount,
  registered: puzzlesAmount,
  premium: puzzlesAmount,
  name: "config"
}

@Injectable({
  providedIn: 'root'
})
export class PuzzlesConfigService {

  backendUrl = environment.backendUrl;

  constructor(private http: HttpClient) { }

  getConfigPromise(): Promise<PuzzlesPublishingConfig> {
    return this.http.get<PuzzlesPublishingConfig>(`${this.backendUrl}/api/defaultValuesConfig`).toPromise()
  }
  
  getConfig(): Observable<PuzzlesPublishingConfig> {
    return this.http.get<PuzzlesPublishingConfig>(`${this.backendUrl}/api/defaultValuesConfig`)
  }
  
  updateConfig(config: PuzzlesPublishingConfig): Observable<PuzzlesPublishingConfig> {
    return this.http.put<PuzzlesPublishingConfig>(`${this.backendUrl}/api/defaultValuesConfig`, config)
  }
}
