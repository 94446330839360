import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateNotificationViewComponent } from './create-notification-view/create-notification-view.component';
import { UsersSegmentsModule } from './users-segments/users-segments.module';
import { NotificationFormComponent } from './create-notification-view/notification-form/notification-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateNotificationService } from './create-notification-view/create-notification.service';
import { PhoneTemplateModule } from './create-notification-view/phone-template/phone-template.module';
import { TestNotificationDeviceModalComponent } from './create-notification-view/test-notification-device-modal/test-notification-device-modal.component';
import { ScheduledNotificationFormComponent } from './create-notification-view/scheduled-notification-form/scheduled-notification-form.component';

@NgModule({
  declarations: [CreateNotificationViewComponent, NotificationFormComponent, TestNotificationDeviceModalComponent, ScheduledNotificationFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UsersSegmentsModule,
    PhoneTemplateModule
  ],
  exports: [CreateNotificationViewComponent],
  providers: [CreateNotificationService]
})
export class CreateNotificationViewModule { }
