<div *ngIf="!loading" class="scroll" (scroll)="onScroll($event)">
    <div *ngFor="let puzzle of puzzles; let index = index" class="col-12"
        [ngClass]="{'done': puzzle.status === 2, 'bin': puzzle.status === -1, 'undone': puzzle.status === 0}" style="border-bottom:1px solid grey">
        <div class="row">
            <div class="col-1">
                <p style="margin-top:15px;">{{index+1}}.</p>
            </div>
            <div class="col-3">
                <p style="margin-top:15px;">{{puzzle.day | date:'dd.MM.yyyy'}}</p>
            </div>
            <div class="col-2">
                <p style="margin-top:15px;">{{puzzle.uuid}}</p>
            </div>
            <div style="padding:5px" class="col-2">
                <button (click)="openPuzzle(puzzle);" class="btn btn-default btn-block">Bearbeiten
                </button>
            </div>
            <div class="col-2">
                {{puzzle.points}}
                <!-- <button (click)="pushPuzzle(puzzle)" class="btn btn-danger">
                    Veröffentlichen als Premium
                </button> -->
            </div>
            <div class="col-2">
                <button (click)="pushPuzzle(puzzle)" class="btn btn-danger">
                    Veröffentlichen als Premium
                </button>
            </div>
        </div>
    </div>
</div>
<p *ngIf="loading">
    loading...
</p>