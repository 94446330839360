import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PuzzlesService } from '../../../puzzles.service';

@Component({
  selector: 'app-edit-puzzle-answers',
  template: `
      <div style="overflow:scroll;height:600px">
        <h5>Fragen und Antworten: </h5>
        <div *ngFor="let questionArr of questions; index as j">
            <div class="row">
                <ng-container *ngFor="let question of questionArr; index as i">
                    <div class="col-12" *ngIf="question.question_num != null">
                        <p>Frage: {{question.question_num}}.
                        <p *ngIf="checkNull(question.question_vertical)">Vertikal:
                            <br>
                            <input [disabled]="disabled" (focus)="nextQuestionNum(question.question_num)"
                                (blur)="updateQuestion(question, i, j)" [(ngModel)]="question.question_vertical"
                                class="form-control" />

                        </p>
                        <br />
                        <p *ngIf="checkNull(question.question_horizontal)">
                            Horizontal:
                            <br>
                            <input [disabled]="disabled" (focus)="nextQuestionNum(question.question_num)"
                                (blur)="updateQuestion(question, i, j)"
                                [(ngModel)]="question.question_horizontal" class="form-control" />
                        </p>

                    </div>
                </ng-container>
            </div>
        </div>
      </div>
  `,
  styles: [``]
})
export class EditPuzzleAnswersComponent implements OnInit {

  constructor(
    private puzzleService: PuzzlesService
  ) { }

  @Input() questions: Array<any> = [];
  @Input() puzzle: any = {}

  @Input() disabled: boolean = false;

  @Output() onNextQuestionNum = new EventEmitter<number>()



  ngOnInit(): void {
  }

  checkNull(property: string) {
    return !!property;
  }

  updateQuestion(question: any, x: number, y: number) {
    question.xo = x;
    question.yo = y;
    this.puzzleService
      .updateQuestion(this.puzzle._id, question)
      .subscribe(
        (s) => {
          console.log(s, 'succes');
        },
        (err => {
          console.log(err, 'err');
        })
      )
  }

  nextQuestionNum(questionNum: number) {
    console.log(questionNum, 'nextQuestionNum');  
    this.onNextQuestionNum.emit(questionNum)
  }

}
