
<div #containerRef>
    <ngx-charts-line-chart
    [view]="[containerRef.offsetWidth, 375]"
    [scheme]="colorScheme"
    [legend]="legend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxis]="xAxis"
    [yAxis]="yAxis"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [timeline]="timeline"
    [results]="multi"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)"
    >
  </ngx-charts-line-chart>
</div>

