import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-segements-modal',
  template: `
   <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Add Segment</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  <div (click)="addSegment(segment)" *ngFor="let segment of segments" class="segment-group pointer">
    <div class="segment-select">
        <div class="segment-new editable">
            <div class="segment-grabber"><i class="fa fa-pie-chart"></i></div>
            <div class="segment-content flex justified">
                <div class="segment-name">{{segment}}</div>
                <div class="segment-badge"></div>
                <div class="flex center"><span class="segment-icons"><span class="segment-icon-well"><i
                                class="fa fa-sign-out"></i></span></span><button class="sc-jDwBTQ cgTVfR"
                        type="button"></button></div>
            </div>
        </div>
    </div>
</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close()">Ok</button>
  </div>
  `,
  styleUrls: ['./../users-segements/users-segements.component.scss']
})
export class AddSegementsModalComponent {

  @Input() segments = ['Free', 'Trial', 'Premium']

  constructor(public modal: NgbActiveModal) { }

  addSegment(segment: string) {
    this.modal.close(segment)
  }

}
