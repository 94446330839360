import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CreateNotificationService {

  // backendUrl = environment.backendUrl;

  // constructor(private http: HttpClient) { }

  // getPuzzles(cat: string, paginateData: PaginateData): Observable<any> {
  //   return this.http.get(`${this.backendUrl}/api/usersView/puzzle`, {
  //     params: {
  //       page: `${paginateData.page}`,
  //       pageSize: `${paginateData.pageSize}`,
  //       category: cat
  //     }
  //   }).pipe(
  //     map((response: any) => {
  //       response = response.list.filter(
  //         (puzzle: Puzzle) => {
  //           return puzzle.premium == true || puzzle.daily == true || puzzle.dailymini == true || puzzle.open == true
  //         }) as Puzzle[]
  //       return response
  //     }))
  // }

  // getSinglePuzzle(id: string): Observable<any> {
  //   return this.http.get(`${this.backendUrl}/api/puzzle/${id}`);
  // }

  // getManyPuzzle(puzzlesIds: string[]): Observable<any> {
  //   return this.http.post(`${this.backendUrl}/api/puzzleView/puzzlesIds`, puzzlesIds);
  // }


  // updatePuzzle(id: string, puzzle: Puzzle): Observable<any> {
  //   return this.http.put(`${this.backendUrl}/api/puzzle/${id}`, puzzle);
  // }

  // updateQuestion(id: string, question: any): Observable<any> {
  //   return this.http.put(`${this.backendUrl}/api/puzzle/question/${id}`, question);
  // }

  public refreshNotificationData$ = new Subject();


  
}
