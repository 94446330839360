import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { EditPuzzleModalComponent } from '../../puzzle-view/puzzle-edit/edit-puzzle-modal/edit-puzzle-modal.component';
import { PuzzlesService } from '../../puzzle-view/puzzles.service';
import { Puzzle } from '../../puzzle-view/table-puzzle/table-puzzle.component';
import { PaginateData } from '../../users-view/users-table/users-table.component';
import { PuzzleSearchService } from '../puzzle-search.service';


export type searchCategory = 'day' | 'uuid'

@Component({
  selector: 'app-puzzle-search-view',
  templateUrl: './puzzle-search-view.component.html',
  styles: [`

      .scroll {
          display: block;
          overflow-y: scroll;
          overflow-x: scroll;
          max-height: 900px;
          scroll-behavior:auto;
      }

      .inline-block {
          display: inline-block;
      }

      .pointer {
          cursor: pointer;
      }

      tr.selected:hover {
        background-color: rgb(0 255 34 / 5%);
      }

    `]
})
export class PuzzleSearchViewComponent {

  searchId = ''
  searchPublishDate = ''

  paginate: PaginateData = {
    page: 1,
    pageSize: 20
  }
  loading = true;

  @Input() puzzles: Puzzle[] = []

  @Output() nextpuzzle = new EventEmitter()

  filter = new FormControl('');

  onScroll(event: any) {

    const scrolledBottom = event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight

    if (scrolledBottom) {
      this.paginate.page++;
      this.getPuzzles()
    }
  }

  constructor(
    private modalSerivce: NgbModal,
    private puzzleService: PuzzlesService,
    private toastrS: ToastrService,
    private puzzleSearchService: PuzzleSearchService) {
    this.getPuzzles()
  }

  getPuzzles() {
    this.puzzleService
      .getPuzzles('all', this.paginate)
      .subscribe(el => {
        this.loading = false;
        this.puzzles = [...this.puzzles, ...el]
      })
  }


  search(category: searchCategory) {

    let typing = this.searchId

    if (category === 'day') {
      typing = this.searchPublishDate
    } else {
      if (this.searchId.length < 3) {
        return this.toastrS.error('Please insert puzzle Id')
      }
      typing = this.searchId
    }

    this.puzzleSearchService
      .searchPuzzles(category, typing)
      .pipe(map(res => (res.list as Puzzle[])),
        map(puzzles => {
          return puzzles.map(puzzle => {
            return {
              ...puzzle
            }
          })
        })
      )
      .subscribe(searchedpuzzles => {
        this.puzzles = searchedpuzzles
      })
  }

  reset() {
    this.searchId = ''
    this.paginate = {
      page: 1,
      pageSize: 20
    }
    this.puzzles = []
    this.getPuzzles()
  }

  openPuzzle(editPuzzle: Puzzle) {
    this.puzzleService.getSinglePuzzle(editPuzzle._id)
      .subscribe(
        (puzzle) => {
          const puzzleModal = this.modalSerivce.open(EditPuzzleModalComponent, {
            size: 'xl'
          })
          puzzleModal.componentInstance.editPuzzle = editPuzzle;
          puzzleModal.componentInstance.editPuzzle.points = editPuzzle.totalGrid;

          puzzleModal.componentInstance.puzzle = puzzle
        })

  }

}
