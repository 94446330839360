import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

export interface filter {
  daily?: boolean,
  dailymini?: boolean,
  open?: boolean | undefined,
  premium?: boolean | undefined,
  status?: string | number | any
  category: string
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  filter: filter = {
    status: undefined,
    dailymini: false,
    daily: true,
    category: 'maxi'
  }

  section = -1;

  constructor(
    private auth: AuthService,
    private route: Router,
    private toastr: ToastrService,
  ) { }

  signOut() {
    this.auth.logout()
      .subscribe(
        () => {
          this.route.navigate(['/login']);
        },
        () => {
          this.toastr.error('Somtehing went wrong');
        }
      );
  }

  changePuzzleTable(chosenSection: number, filter: filter) {
    this.section = chosenSection
    this.filter = filter    
  }

}
