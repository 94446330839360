<app-nav-bar></app-nav-bar>

<div class="container-fluid p-0 pt-4">
    <div class="row">
        <div style="background-color:#1c8186" class="col-3">
            <div class="row">
                <div class="col-12">
                    <p><b>
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">Rätsel</font>
                            </font>
                        </b></p>
                </div>
            </div>
            <div class="row">
                <div class="col-11 offset-sm-1">
                    <p (click)="changePuzzleTable(-1, { status: '2', daily: true, dailymini: false, open: true, category: 'maxi' })"
                        [ngClass]="{'white': section == -1}" class="">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">Tages-Maxi</font>
                        </font>
                    </p>
                    <p (click)="changePuzzleTable(0 ,{ status: '2', daily: false, dailymini: true, open: true, category: 'mini' });"
                        [ngClass]="{'white': section == 0}" class="white">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">Tages-Mini</font>
                        </font>
                    </p>
                    <p (click)="changePuzzleTable(2 ,{ status: '2', daily: false, dailymini: true, open: true, category: 'mini' });"
                        [ngClass]="{'white': section == 2}">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">Premium</font>
                        </font>
                    </p>
                    <p (click)="changePuzzleTable(3 ,{ status: '2', daily: false, dailymini: true, open: true, category: 'mini' });"
                        [ngClass]="{'white': section == 3}">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">Rätselsuche</font>
                        </font>
                    </p>
                    <p (click)="changePuzzleTable(4 ,{ status: '2', daily: false, dailymini: true, open: true, category: 'mini' });"
                        [ngClass]="{'white': section == 4}">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">Papierkorb</font>
                        </font>
                    </p>

                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p><b class="n">
                            <font [ngClass]="{'white': section == 8}" (click)="section = 8; filter= {status: 3, category: 'maxi'};"
                                style="vertical-align: inherit;"> User-Datenbank
                            </font>
                        </b>
                    </p>
                </div>
            </div>        
            <div class="row">
                <div class="col-12">
                    <p><b class="n">
                            <font [ngClass]="{'white': section == 9}" (click)="section = 9; filter= {status: 3, category: 'maxi'};"
                                style="vertical-align: inherit;"> Statistik
                            </font>
                        </b>
                    </p>
                </div>
            </div>        
            <div class="row">
                <div class="col-12">
                    <p><b class="n">
                            <font [ngClass]="{'white': section == 10}" (click)="section = 10; filter= {status: 3, category: 'maxi'};"
                                style="vertical-align: inherit;"> Push-Nachrichten
                            </font>
                        </b>
                    </p>
                </div>
            </div>        
            <div class="row">
                <div class="col-12">
                    <p><b class="n">
                            <font [ngClass]="{'white': section == 11}" (click)="section = 11; filter= {status: 3, category: 'maxi'};"
                                style="vertical-align: inherit;"> Create-Nachrichten
                            </font>
                        </b>
                    </p>
                </div>
            </div>        
        </div>
        <div class="col-9">
            <div class="row">
                <div class="col-12">
                    <!-- <h1 *ngIf="filter.status === undefined ">Alle nicht lektorierten Rätsel</h1> -->
                    <!-- <h1 *ngIf="filter.status == '2' ">Alle verfügbaren Rätsel</h1> -->
                    <h1 *ngIf="section == 8 ">User-Datenbank & Übersicht</h1>
                </div>
            </div>
            <span [ngSwitch]="section">
                <app-users-layout *ngSwitchCase="8" class="row"></app-users-layout>
                <app-puzzle-view *ngSwitchDefault [filter]="filter" class="row"></app-puzzle-view>
                <app-puzzle-view *ngSwitchCase="-1" [filter]="filter" class="row"></app-puzzle-view>
                <app-puzzle-view *ngSwitchCase="0" [filter]="filter" class="row"></app-puzzle-view>
                <app-premium-view *ngSwitchCase="2"></app-premium-view>
                <app-bin-puzzle-view *ngSwitchCase="4"></app-bin-puzzle-view>
                <app-puzzle-search-view *ngSwitchCase="3"></app-puzzle-search-view>
                <app-statistics-view *ngSwitchCase="9"></app-statistics-view>
                <app-notifications-view *ngSwitchCase="10"></app-notifications-view>
                <app-create-notification-view *ngSwitchCase="11"></app-create-notification-view>
            </span>
        </div>
    </div>
</div>
<!-- <app-betatesters-list></app-betatesters-list> -->