import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';

interface User {
  name: string;
}

interface Credentials {
  email: string;
  password: string;
}

interface Session {
  token: string;
  user: User;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  backendUrl = environment.backendUrl;

  private session = new BehaviorSubject<Session>(null as any);

  isAuthenticated = false;

  state: Observable<boolean> = this.session.pipe(
    map( session => !!session),
    tap( state => this.isAuthenticated = state)
  )

  getToken() {
    const session = this.session.getValue();
    return session && session.token;

  }

  getCurrentUser(): Session | User  {
    const session = this.session.getValue();
    return session && session.user;
  }

  constructor(private http: HttpClient) { }

  login(credentials: Credentials): Observable<any> {
    return this.http.post(`${this.backendUrl}/login`, credentials)
    .pipe(tap( session => this.session.next(session as Session)));
  }

  logout(): Observable<any> {
    return this.http.post(`${this.backendUrl}/logout`, {})
    .pipe(tap( session => this.session.next(session as Session)));
  }

  register(credentials: Credentials): Observable<any> { 
    return this.http.post(`${this.backendUrl}/register`, credentials);
  }

  test(): Observable<any> {
    console.log('requestdone')
    return this.http.get(`${this.backendUrl}/api/user/getAll`);
  }

}
