<div class="mb-4">
    <h3 class="segment-select">{{header}}</h3>
    <div *ngFor="let segment of segmentsDisplay" class="segment-group">
        <div class="segment-select">
            <div class="segment-new editable">
                <div class="segment-grabber"><i class="fa fa-pie-chart"></i></div>
                <div class="segment-content flex justified">
                    <div class="segment-name">{{segment}}</div>
                    <div class="segment-badge"></div>
                    <div class="flex center" (click)="removeSegment(segment)"><span class="segment-icons"><span
                                class="segment-icon-well"><i class="fa fa-sign-out"></i></span></span><button
                            class="sc-jDwBTQ cgTVfR" type="button">X</button></div>
                </div>
            </div>
        </div>
    </div>

    <div class="add-segment">
        <button (click)="opeadAddSegmentModal()" class="sc-jKJlTe dLJMnT"><svg viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor"
                class="sc-bdVaJa AYviX">
                <path
                    d="M.333 8A7.667 7.667 0 108 .333 7.675 7.675 0 00.333 8zm3.334-.333A.667.667 0 014.333 7h2.5A.167.167 0 007 6.833v-2.5a.667.667 0 01.667-.666h.666A.667.667 0 019 4.333v2.5A.167.167 0 009.167 7h2.5a.667.667 0 01.666.667v.666a.667.667 0 01-.666.667h-2.5A.167.167 0 009 9.167v2.5a.667.667 0 01-.667.666h-.666A.667.667 0 017 11.667v-2.5A.167.167 0 006.833 9h-2.5a.667.667 0 01-.666-.667v-.666z"
                    fill="currentColor"></path>
            </svg><span class="sc-dxgOiQ hTjMfW">Add Segment</span></button>
    </div>
</div>