
import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { DailyPackagesService } from 'src/app/daily-packages.service ';
import { PuzzlesConfigService, PuzzlesPublishingConfig } from 'src/app/puzzles-config.service';
import { AccountDefaultValues } from '../../default-values-settings/default-values-settings.component';
import { PuzzlesPublishingService } from '../../puzzles-publishing.service';
import { DailyPackages, puzzleData } from '../publishing-calendar.component';
import { PackgesPuzzlesConfigModalComponent } from './packges-puzzles-config-modal/packges-puzzles-config-modal.component';

@Component({
  selector: 'nz-modal-custom-footer-component',
  template: `
    <div>
    <nz-tabset>
      <nz-tab (nzSelect)="provideCategoryForConfig('lite')" nzTitle="Lite">
      <app-puzzles-values-view [listOfAccountsDefaultValues]="package" [category]="'Lite'"></app-puzzles-values-view>
    </nz-tab>
    <nz-tab  (nzSelect)="provideCategoryForConfig('registered')"  nzTitle="Registered">
      <app-puzzles-values-view [listOfAccountsDefaultValues]="package" [category]="'Registered'"></app-puzzles-values-view>
    </nz-tab>
    <nz-tab  (nzSelect)="provideCategoryForConfig('premium')"  nzTitle="Premium">
      <app-puzzles-values-view [listOfAccountsDefaultValues]="package" [category]="'Premium'"></app-puzzles-values-view>
      </nz-tab>
    </nz-tabset>
    <div class="maxi-mini-buttons">
      <nz-button-group [nzSize]="'large'">
        <button nz-button nzType="primary" (click)="showMaxiPuzzlesConfig()" ><i class="i-left" nz-icon nzType="left"></i>GO MAXI</button>
        <button nz-button nzType="primary" (click)="showMiniPuzzlesConfig()" >GO MINI<i nz-icon class="i-right" nzType="right"></i></button>
      </nz-button-group>
    </div>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <button class="package-cancell-button" [disabled]="dailyPackage?.canceled" (click)="cancellPackage()" nz-button nzType="primary" nzDanger>Cancell Package</button>
    </div>
    <div *nzModalFooter>
      <button nz-button nzType="default" (click)="destroyModal()">Cancell</button>
      <button nz-button nzType="primary" (click)="destroyModal()">Ok</button>
    </div>
  `,
  styles: [
    `
      [nz-button] {
        margin-right: 8px;
        margin-bottom: 12px;
      }

      .maxi-mini-buttons {
        text-align: center;

      }

      .package-cancell-button {
        position: relative;
        top: 36px;
        left: -24px;
      }

      .i-left, .i-right {
        position: relative;
        top: -3px;
      }

    `
  ]
})
export class PackageSettingModalComponent implements OnInit {

  package: AccountDefaultValues[] = [];
  profilesCategory: 'lite' | 'registered' | 'premium' = 'lite'
  config: PuzzlesPublishingConfig = { lite: { maxi: 0, mini: 0 }, premium: { maxi: 0, mini: 0 }, registered: { maxi: 0, mini: 0 }, name: 'config' }

  constructor(
    private modal: NzModalRef, private modalService: NzModalService,
    private serviceDaily: DailyPackagesService,
    private servicePublishing: PuzzlesConfigService) { }
  @Input('dailyPackage') dailyPackage: DailyPackages | undefined;

  ngOnInit(): void {


    this.servicePublishing.getConfigPromise()
      .then(config => {
        this.config = config
      })


    if (
      this.dailyPackage?.lite.maxi.length && this.dailyPackage?.lite.mini.length &&
      this.dailyPackage?.lite.maxi.length && this.dailyPackage?.lite.mini.length &&
      this.dailyPackage?.lite.maxi.length && this.dailyPackage?.lite.mini.length
    ) {
      this.package = [
        {
          type: 'Lite',
          maxi: this.dailyPackage?.lite.maxi.length,
          mini: this.dailyPackage?.lite.mini.length,
          total: Number(this.dailyPackage?.lite.maxi.length + this.dailyPackage?.lite.mini.length)
        },
        {
          type: 'Registered',
          maxi: this.dailyPackage?.registered.maxi.length,
          mini: this.dailyPackage?.registered.mini.length,
          total: Number(this.dailyPackage?.registered.maxi.length + this.dailyPackage?.registered.mini.length)
        },
        {
          type: 'Premium',
          maxi: this.dailyPackage?.premium.maxi.length,
          mini: this.dailyPackage?.premium.mini.length,
          total: Number(this.dailyPackage?.premium.maxi.length + this.dailyPackage?.premium.mini.length)
        }
      ]

    }
  }

  provideCategoryForConfig($event: 'lite' | 'registered' | 'premium') {
    this.profilesCategory = $event;
  }

  destroyModal(): void {
    this.modal.destroy();
  }

  showMaxiPuzzlesConfig() {
    const maxi = this.modalService.create({
      nzTitle: 'Maxi Setting',
      nzContent: PackgesPuzzlesConfigModalComponent,
      nzWidth: '900px'
    })

    if (maxi.componentInstance && this.dailyPackage) {
      maxi.componentInstance.dailyPackageConfig = {
        dailyPackage: this.dailyPackage,
        profilType: this.profilesCategory,
        puzzlesCategory: 'maxi',
        puzzlesNumber: this.config[this.profilesCategory]['maxi']
      }
    }
  }

  showMiniPuzzlesConfig() {
    const maxi = this.modalService.create({
      nzTitle: 'Mini Setting',
      nzContent: PackgesPuzzlesConfigModalComponent,
      nzWidth: '900px'
    })

    if (maxi.componentInstance && this.dailyPackage) {
      maxi.componentInstance.dailyPackageConfig = {
        dailyPackage: this.dailyPackage,
        profilType: this.profilesCategory,
        puzzlesCategory: 'mini',
        puzzlesNumber: this.config[this.profilesCategory]['mini']
      }
    }
  }

  cancellPackage() {
    this.modalService.confirm(
      {
        nzTitle: 'Cancell Package',
        nzContent: `Are you sure you want cancell current daily package?`,
        nzOnOk: (component) => {
          if (this.dailyPackage) {
            this.dailyPackage.canceled = true;
            return this.serviceDaily.updateConfig(this.dailyPackage).toPromise()
              .then(r => {

              })
          }
        }
      })
  }

}
