import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class PuzzleSearchService  {

  backendUrl = environment.backendUrl;

  constructor(private http: HttpClient) { }

  searchPuzzles(cat: string, typing: string): Observable<any> {
    return this.http.get(`${this.backendUrl}/api/puzzlesSearchView/search`, {
      params: {
        searchCategory: cat,
        typed: typing
      }
    })
  }


}
