import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PuzzlesService } from '../../../puzzles.service';
import { Puzzle } from '../../../table-puzzle/table-puzzle.component';
import { EditPuzzleBinConfirmModalComponent } from '../../edit-puzzle-modal/edit-puzzle-bin-confirm-modal/edit-puzzle-bin-confirm-modal.component';

@Component({
  selector: 'app-edit-puzzle-save',
  template: `
    <div [ngClass]="{'disabled': disabled}" class="col-6">
    <div class="left-col ml-2">
    <div>
      Rätsel-ID: {{editPuzzle?.uuid}}
      <br>
      Veröffentlichung:
      <input placeholder="label" type="date" [disabled]="disabled" (ngModelChange)="editedDay = $event" [ngModel]="editPuzzle.day | date:'yyyy-MM-dd'">
      </div>
      <div>
          <button style="margin-bottom: 5px;" nz-button nzType="primary" [disabled]="disabled" (click)="openPuzzleBinConfirmModal(editPuzzle)" nzDanger>Rätsel verwerfen</button>
      </div>    
    </div>
    </div>
    <div [ngClass]="{'disabled': disabled}" class="col-6">
          <div class="mr-2">
          Maxi-Tagesrätsel
          Punkte: {{editPuzzle.points}} 
            <div style="margin-top:20px">
            <button [disabled]="disabled"  nz-button nzType="primary" (click)="editPuzzle.status=2;updatePuzzle(editPuzzle);editPuzzle=undefined" nzBlock>Rätsel veröffentlichen</button>
            </div>
        </div>
    </div>
  `,
  styles: [`
  
    .save-button {
      margin-bottom: 20px;
    }

    .btn-verwerfen {
      margin-top: 50px;
    }

    .disabled {
      opacity: 0.4;
    }
  
  `]
})
export class EditPuzzleSaveComponent implements OnInit {


  @Input() disabled: boolean = false;
  @Input() editPuzzle: any;

  editedDay = '';

  @Output() onPuzzleMovedBin = new EventEmitter()


  constructor(
    private puzzleService: PuzzlesService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void { }

  openPuzzleBinConfirmModal(editPuzzle: Puzzle) {
    this.puzzleService.getSinglePuzzle(editPuzzle._id)
      .subscribe(
        (puzzle) => {
          const puzzleModal = this.modalService.open(EditPuzzleBinConfirmModalComponent, {
            size: 'ml'
          })
          puzzleModal.componentInstance.editPuzzle = editPuzzle;
          puzzleModal.componentInstance.editPuzzle.points = editPuzzle.totalGrid;
          puzzleModal.componentInstance.puzzle = puzzle
          puzzleModal.result.then(r => {

            this.onPuzzleMovedBin.emit(editPuzzle._id)
          }).catch(s => console.log(s)
          )
        })

  }

  updatePuzzle(puzzle: Puzzle) {

    const date = new Date(this.editedDay).getTime()

    if (date === date) {
      puzzle['day'] = new Date(this.editedDay).toString()
    }

    this.puzzleService
      .updatePuzzle(puzzle._id, puzzle)
      .subscribe(
        (s) => {
          this.onPuzzleMovedBin.emit(puzzle._id)
        }, (err => {
          console.log(err, 'err');
        }))
  }

}
