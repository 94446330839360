import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EditPuzzleModalComponent } from './edit-puzzle-modal/edit-puzzle-modal.component';
import { EditPuzzleBinConfirmModalComponent } from './edit-puzzle-modal/edit-puzzle-bin-confirm-modal/edit-puzzle-bin-confirm-modal.component';
import { EditPuzzleFormComponent } from './edit-puzzle-form/edit-puzzle-form.component';
import { EditPuzzleCrossWordComponent } from './edit-puzzle-components/edit-puzzle-cross-word/edit-puzzle-cross-word.component';
import { EditPuzzleAnswersComponent } from './edit-puzzle-components/edit-puzzle-answers/edit-puzzle-answers.component';
import { EditPuzzleSaveComponent } from './edit-puzzle-components/edit-puzzle-save/edit-puzzle-save.component';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [
    EditPuzzleModalComponent,
    EditPuzzleBinConfirmModalComponent,
    EditPuzzleFormComponent,
    EditPuzzleCrossWordComponent,
    EditPuzzleAnswersComponent,
    EditPuzzleSaveComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule
  ],
  exports: [EditPuzzleFormComponent, EditPuzzleCrossWordComponent]
})

export class PuzzleEditModule { }
