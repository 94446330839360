<div>
    <div class="form-group form-inline inline-block  mr-2">
        search by user name: <input class="form-control ml-2 " type="text" [(ngModel)]="searchName" name="searchName"
            (keyup.enter)="search()" />
    </div>
    <div class="form-group form-inline inline-block">
        search by email: <input class="form-control ml-2" type="text" [(ngModel)]="searchEmail" name="searchEmail"
            (keyup.enter)="search()" />
    </div>
    <button class="ml-2" nz-button nzType="primary" (click)="reset()">Reset</button>
    <button nz-button nzType="default"  (click)="search()">Search</button>
    <!-- <button class="btn btn-danger ml-2" (click)="reset()">Reset</button> -->
    <!-- <button class="btn btn-info ml-2 " (click)="search()">Search</button> -->
</div>

<div *ngIf="!loading" class="scroll" (scroll)="onScroll($event)">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Registered at</th>
                <th scope="col">Status</th>
            </tr>
        </thead>
        <tbody>
            <tr class="selected"  *ngFor="let user of users$; index as i">
                <th scope="row">{{ i + 1 }}</th>
                <td class="pointer" (click)="nextUser.emit(user)">
                    {{user.displayName}}
                </td>
                <td class="pointer" (click)="nextUser.emit(user)">
                    {{user.email}}
                </td>
                <td>
                    {{user.date | date: 'medium' }}
                </td>
                <td>
                    <ngb-highlight [result]="user.memberShip | memberShipString" [term]="filter.value"></ngb-highlight>
                </td>
                <td>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p *ngIf="loading" >
    Loading...
</p>