<div>
    <div class="phone">
        <div class="sc-ekHBYt gDsQHl phone-background">
            <div class="sc-eTyWNx MYmHo phone-notification-container">
                <div class="sc-ccXozh phone-notification">
                    <div class="sc-bsVVwV hnjSVP phone-notification-heading">
                        <span class="sc-iVOTot phone-notification-heading-icon"></span>
                        <span class="sc-exdmVY phone-notification-heading-title">{{notificationData.title}}</span>
                        <span class="sc-kOCNXg hKHBRs"></span>
                    </div>
                    <div class="sc-evWYkj iFxjGE phone-notification-body-container" >
                        <div class="sc-hZhUor kOkFzt phone-notification-body-margin">
                            <div class="sc-jMvuUo cuNUrH phone-notification-body">
                                <div class="sc-idjmjb iTvQvE phone-notification-body-title">{{notificationData.subtitle}}</div>
                                <div class="sc-fHlXLc egrsKK phone-notification-body-text">{{notificationData.message}}</div>
                            </div>
                        </div><img src="" class="sc-AqAhp gTxpJm">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>