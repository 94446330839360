import { Component, Input } from '@angular/core';
import { CreateNotificationService } from '../../create-notification.service';

export type os = 'ios' | 'android'
@Component({
  selector: 'app-phone',
  template: `
    <span>
        <app-phone-android [ngClass]="{'display' : system !== 'android'}" ></app-phone-android>
        <app-phone-apple [ngClass]="{'display' : system !== 'ios'}" ></app-phone-apple>
    </span>
    `,
    styles: [`
      .display {
        display: none;
      }
    `]
})
export class PhoneComponent  {

  @Input() public system: os = 'android'

  constructor(private notificationS: CreateNotificationService) { }

}
