import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Puzzle } from 'src/app/dashboard/dashboard/puzzle-view/table-puzzle/table-puzzle.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PuzzlesPublishingService {

  backendUrl = environment.backendUrl;

  constructor(private http: HttpClient) { }

  getPuzzlesUuids(cat): Observable<any> {
    return this.http.get(`${this.backendUrl}/api/puzzleView/puzzlesUuids`, {
      params: {
        category: cat
      }
    })
  }

  getPuzzleByUuid(uuid: string[]): Observable<Puzzle[]> {
    return this.http.post<Puzzle[]>(`${this.backendUrl}/api/puzzleView/getPuzzlesByUuid`, {uuidsArr: uuid});
  }

  // getManyPuzzle(puzzlesIds: string[]): Observable<any> {
  //   return this.http.post(`${this.backendUrl}/api/puzzleView/puzzlesIds`, puzzlesIds);
  // }


  // updatePuzzle(id: string, puzzle: Puzzle): Observable<any> {
  //   return this.http.put(`${this.backendUrl}/api/puzzle/${id}`, puzzle);
  // }

  // updateQuestion(id: string, question: any): Observable<any> {
  //   return this.http.put(`${this.backendUrl}/api/puzzle/question/${id}`, question);
  // }

  // public refreshPuzzleTable$ = new BehaviorSubject(null as any);


}
