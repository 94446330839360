export var multi = [
    {
      "name": "Free",
      "series": [
        {
          "name": "2020.05.20",
          "value": 200
        },
        {
          "name": "2020.07.11",
          "value": 400
        },
        {
          "name": "2020.09.11",
          "value": 600
        },
        {
          "name": "2020.11.11",
          "value": 800
        },
        {
          "name": "2021.1.26",
          "value": 1200
        }
      ]
    },
  
    {
      "name": "Trial",
      "series": [
        {
          "name": "2020.05.20",
          "value": 200
        },
        {
          "name": "2020.07.11",
          "value": 300
        },
        {
          "name": "2020.08.11",
          "value": 400
        },
        {
          "name": "2020.11.11",
          "value": 300
        },
        {
          "name": "2021.1.26",
          "value": 250
        }
      ]
    },
  
    {
      "name": "Premium",
      "series": [
        {
          "name": "2020.05.20",
          "value": 0
        },
        {
          "name": "2020.07.11",
          "value": 200
        },
        {
          "name": "2020.08.11",
          "value": 250
        },
        {
          "name": "2020.11.11",
          "value": 300
        },
        {
          "name": "2021.1.26",
          "value": 400
        }
      ]
    }
  ];
  