import { Component, OnInit } from '@angular/core';


export var single = [
  {
    "name": "Free",
    "value": 1500
  },
  {
    "name": "Trial",
    "value": 900
  },
  {
    "name": "Premium",
    "value": 300
  }
];

@Component({
  selector: 'app-users-pie-chart',
  templateUrl: './users-pie-chart.component.html',
  styleUrls: ['./users-pie-chart.component.scss']
})
export class UsersPieChartComponent  {
  
  single: any[] = [];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  constructor() {
    Object.assign(this, { single });
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
