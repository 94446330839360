import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneComponent } from './phone/phone.component';
import { PhoneAndroidComponent } from './phone-android/phone-android.component';
import { PhoneAppleComponent } from './phone-apple/phone-apple.component';

@NgModule({
  declarations: [PhoneComponent, PhoneAndroidComponent, PhoneAppleComponent],
  imports: [
    CommonModule
  ],
  exports: [PhoneComponent]
})
export class PhoneTemplateModule { }
