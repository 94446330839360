import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PuzzlesService } from '../../puzzles.service';
import { Puzzle } from '../../table-puzzle/table-puzzle.component';
import {preloadPuzzle} from './preloadPuzzle'

@Component({
  selector: 'app-edit-puzzle-form',
  template: `
  <div class="modal-xl form-border mb-4">
  <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          <h2>Rätsel bearbeiten
            <span *ngIf="currentPuzzle.daily==true">
              MAXI
            </span>
            <span *ngIf="currentPuzzle.dailymini==true">
              MINI
            </span>
          </h2>
        </h4>
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden=true>&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngClass]="{'disabled': disabled}">
        <div class="row">
            <div class="col">
                <app-edit-puzzle-cross-word  [puzzle]="currentPuzzle" [question_num]="question_num"></app-edit-puzzle-cross-word>
            </div>
            <div class="col">
                <app-edit-puzzle-answers [disabled]="disabled" [questions]="questions" [puzzle]="currentPuzzle"
                    (onNextQuestionNum)="setNextQuestionNum($event)"></app-edit-puzzle-answers>
            </div>
        </div>
    </div>
    <app-edit-puzzle-save [disabled]="disabled"  class="row" (onPuzzleMovedBin)="onPuzzleMovedBin($event)" [editPuzzle]="currentPuzzle">
    </app-edit-puzzle-save>
  </div>
  `,
  styles: [`
    .disabled {
        opacity: 0.4;
      } 
      
    .form-border {
      border: 1px solid grey;
    }
    .btn-success {
      margin-bottom: 20px;
    }
      
  `]
})
export class EditPuzzleFormComponent implements OnInit {


  first = true;

  questions: any = []
  question_num = 1;

  currentPuzzle: any = {}

  puzzlesQueue: Puzzle[] = []
  disabled: boolean = false;

  @Output() onEditedPuzzle = new EventEmitter()

  @Input() set puzzlesArr(puzzlesArr: Puzzle[]) {
    
    if(puzzlesArr.length < 1) {
      return
    }

    this.disabled = false;
    this.puzzlesQueue.push(...puzzlesArr)

    if (this.first) {
      this.first = false;
      console.log(puzzlesArr.length, 'erer');
      this.currentPuzzle = this.puzzlesQueue.shift()
      console.log(puzzlesArr.length, 'erer');
      this.setQuestions()
    }

  };

  @Input() puzzlesCategory: 'MAXI' | 'MINI' = 'MAXI'

  constructor(
    private puzzleService: PuzzlesService) {
      this.preloadPuzzle()
     }

  ngOnInit() {
    this.preloadPuzzle()
   }


  preloadPuzzle() {
    this.currentPuzzle = preloadPuzzle(this.puzzlesCategory)
    this.setQuestions()
    this.disabled = true;
  }

  setQuestions() {
    this.questions = []
    console.log(this.currentPuzzle, 'current');
    
    this.currentPuzzle.grid
      .forEach((cordinatesArr: Array<any>) => {
        cordinatesArr = cordinatesArr
          .filter(cordinate => {
            return cordinate.question_vertical !== null || cordinate.question_horizontal !== null;
          })

        this.questions.push(cordinatesArr);
      })
  }

  setNextQuestionNum(questionNum: number) {
    this.question_num = questionNum;
  }

  onPuzzleMovedBin(_id: string) {
    this.puzzleService.getSinglePuzzle(_id)
      .subscribe(p => { 
        this.onEditedPuzzle.emit(p)
      })
    this.currentPuzzle = this.puzzlesQueue.shift()
    this.setQuestions()
    
  }

}
