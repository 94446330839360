import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
})
export class NavBarComponent  {

  constructor(
    private route: Router,
    private auth: AuthService,
    private toastr: ToastrService
  ) { }

  signOut() {
    this.auth.logout()
      .subscribe(
        (s) => {
          this.route.navigate(['/']);
        },
        () => {
          this.toastr.error('Somtehing went wrong');
        }
      );

  }
}
