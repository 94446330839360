import { Component } from '@angular/core';

export interface Notification {
  contents: [key: string];
  headings: any;
  successful: number //how many were delivered to the Google / Apple / Windows servers
  converted: number //notification clicks
  received: number  //Confirmed Deliveries
  queued_at: number
  failed: number //failed
}

export interface DnotificationDataiveryInfo {
  errored: number,
  failed: number,
  successful: number
}

export interface NotificationData {
  limit: number
  platform_dnotificationDataivery_stats: {
    android: DnotificationDataiveryInfo,
    ios: DnotificationDataiveryInfo
  }
  offset: number
  total_count: number
  notifications: Notification[]
}

@Component({
  selector: 'app-notifications-view',
  template: `
      <app-notifications-table></app-notifications-table>
  `,
  styleUrls: ['./notifications-view.component.scss']
})
export class NotificationsViewComponent {
  constructor(
  ) { }
}
