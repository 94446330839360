/* declarations: NzModalCustomFooterComponent */

import { Component, Input, ViewChild } from '@angular/core';
import { NzMentionComponent } from 'ng-zorro-antd/mention';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { PuzzlesPublishingService } from 'src/app/pages/puzzles-publishing/puzzles-publishing.service';

@Component({
  selector: 'app-package-add-puzzle-modal',
  templateUrl: './package-add-puzzle-modal.component.html',
  styles: []
})
export class PackageAddPuzzleModalComponent {
  isVisible = false;
  isConfirmLoading = false;

  puzzlesIds = [];

  
  @ViewChild('mention') mention;
  data: string[] = []
  inputValue?: string;
  @Input() suggestions = ['afc163', 'benjycui', 'yiminghe', 'RaoHai', '中文', 'にほんご'];
  

  @Input() set category(c: 'MINI' | 'MAXI') {
    console.log(c, 'categorrrrrrrrrr')
    this.service.getPuzzlesUuids(c).subscribe(c => { 
      this.puzzlesIds = c.map( c => c.uuid)
      console.log(this.puzzlesIds, 'ids')
    })
  }

  constructor(private modalService: NzModalService, private service: PuzzlesPublishingService) {
  }

  onSearchChange(d): void {
    this.data = (this.mention as NzMentionComponent).getMentions().map( m => m.replace('@', ""))
    // this.suggestions = prefix === '@' ? this.users : this.tags;
  }

  handleOk(): void {
    this.isConfirmLoading = true;
    setTimeout(() => {
      this.isVisible = false;
      this.isConfirmLoading = false;
    }, 3000);
  }

  handleCancel(): void {
    this.isVisible = false;
  }
}