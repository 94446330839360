import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheduled-notification-form',
  templateUrl: './scheduled-notification-form.component.html',
  styleUrls: ['./scheduled-notification-form.component.scss']
})
export class ScheduledNotificationFormComponent implements OnInit {

  checkedSpecificTime: boolean = false;
  customTimePerTimeZone: boolean = false;


  messageSheduled= "";
  messageOptimization= "";

  constructor() { }

  ngOnInit(): void {
  }

  changeInfoSheduled(status: boolean, message: string) {
    this.messageSheduled = message
    this.checkedSpecificTime = status
  }

  changeInfoOptimization(status: boolean, message: string) {
    this.messageOptimization = message
    this.checkedSpecificTime = status
  }

}
