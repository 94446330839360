import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaginateData } from '../users-view/users-table/users-table.component';

@Injectable()
export class BinPuzzleService {
  backendUrl = environment.backendUrl;

  constructor(private http: HttpClient) { }

  getBinPuzzles(paginateData: PaginateData): Observable<any> {
    return this.http.get(`${this.backendUrl}/api/binView/puzzle`, {
      params: {
        page: `${paginateData.page}`,
        pageSize: `${paginateData.pageSize}`,
      }
    }).pipe(
      map((response: any) => {
        response = response.list
        return response
      }))
  }

  sendBinPuzzlesEmail(puzzlesIds: string[]): Observable<any> {
    return this.http.post(`${this.backendUrl}/api/binView/puzzleEmail`, puzzlesIds)
  }

  public refreshPuzzleTable$ = new Subject();

}
