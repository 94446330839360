import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PuzzlesEditListComponent } from './puzzles-edit-list/puzzles-edit-list.component';
import { PuzzlePreviewPopOverComponent } from './puzzle-preview-pop-over/puzzle-preview-pop-over.component';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { DashboardModule } from 'src/app/dashboard/dashboard.module';

@NgModule({
  declarations: [PuzzlesEditListComponent, PuzzlePreviewPopOverComponent],
  imports: [
    CommonModule,
    NzTableModule,
    NzButtonModule,
    NzInputNumberModule,
    NzCalendarModule,
    NzBadgeModule,
    NzDatePickerModule,
    NzModalModule,
    NzTabsModule,
    NzIconModule,
    NzCardModule,
    NzListModule,
    NzGridModule,
    NzMentionModule,
    NzInputModule,
    NzTypographyModule,
    NzPopconfirmModule,
    NzPopoverModule,
    DashboardModule 

  ],
  exports: [
    PuzzlesEditListComponent,
    PuzzlePreviewPopOverComponent
  ]
})
export class DailyPackageConfigModule { }
