import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Puzzle } from '../../puzzle-view/table-puzzle/table-puzzle.component';
import { PaginateData } from '../../users-view/users-table/users-table.component';
import { BinPuzzleService } from '../bin-puzzle.service';

@Component({
  selector: 'app-bin-puzzle-table',
  templateUrl: './bin-puzzle-table.component.html'
})
export class BinPuzzleTableComponent {

  puzzles: Puzzle[] = []

  puzzlesIds: any = []

  loading = true;

  @Output() onChosenPuzzles = new EventEmitter<Puzzle>()

  constructor(private puzzleService: BinPuzzleService, private modalService: NgbModal) {

    puzzleService.refreshPuzzleTable$
      .subscribe(() => {
        this.refreshView()
      })

  }

  ngOnInit(): void {
    this.getPuzzles()
  }

  paginate: PaginateData = {
    page: 1,
    pageSize: 20
  }

  onScroll(event: any) {
    const scrolledBottom = event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight
    if (scrolledBottom) {
      this.paginate.page++;
      this.getPuzzles()
    }
  }

  getPuzzles() {
    this.puzzleService
      .getBinPuzzles(this.paginate)
      .subscribe(el => {
        this.puzzles = [...this.puzzles, ...el]
        this.loading = false;
      })
  }

  refreshView() {
    this.paginate = {
      page: 1,
      pageSize: 20
    }
    this.puzzles = []
    this.getPuzzles()
  }

  pushPuzzle($event: any, item: Puzzle) {
    if ($event.target.checked) {
      let found = -1;
      for (let i = 0; i < this.puzzlesIds.length; i++) {
        if (this.puzzlesIds[i]._id == item._id) found = i;
      }
      if (found > -1) return;
      else this.puzzlesIds.push(item)
    } else {
      this.puzzlesIds = this.puzzlesIds.filter((p: any) => p._id !== item._id)
    }

    this.onChosenPuzzles.emit(this.puzzlesIds)

  }
}
