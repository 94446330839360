<div class="row">
    <div class="col-12">
        <h1>Puzzle Publish Premium List</h1>
    </div>
</div>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Already Published</a>
      <ng-template ngbNavContent>
        <div *ngFor="let puzzleArr of publishedPuzzles;">
            <br>
            <p style="font-weight: 700; font-weight: 20px;">
                {{puzzleArr[0].day | date:'dd.MM.yyyy'}}
            </p>
            <div>
                <table class="table table-striped table-puzzles-categories">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let puzzle of puzzleArr">
                            <td>
                                <p style="margin-top:15px;">{{puzzle.uuid | puzzlesCategory }}</p>
                            </td>
                            <td>
                                <p style="margin-top:15px;">{{puzzle.uuid}}</p>
                            </td>
                            <!-- <td>
                                <input class="form-control ml-2 input-puzzle-number ml-auto" type="number" [value]="puzzlesMiniLength" disabled />
                            </td> -->
                            <td class="pointer" style="width: 300px;">
                                <button class="btn btn-warning ml-2" (click)="openDeleteUserModal(puzzle)">cancel</button>
                                <button class="btn btn-info ml-2" (click)="openChangePublishDateModal(puzzle)">change publish date</button>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td class="pointer">
                                <button class="btn btn-info ml-2" (click)="changeCategory('MAXI')" type="number">MAXI</button>
                            </td>
                            <td>
                                <input class="form-control ml-2 input-puzzle-number ml-auto" type="number" [value]="puzzlesMaxiLength" disabled />
                            </td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>For Publish</a>
      <ng-template ngbNavContent>
        <div>
            <br>
            <div class="form-group form-inline inline-block  mr-2">
                set amount of puzzles: <input class="form-control ml-2 " type="number" min=0  
                [(ngModel)]="puzzlesCount"
                (blur)="updateSelectedPuzzles()" 
                (change)="updateSelectedPuzzles()" 
                name="searchId" />
            </div>
        
            <div class="form-group form-inline inline-block  mr-2">
                set publishDate: <input placeholder="label" type="date" [(ngModel)]="publishDate" >
            </div>
            <button class="btn btn-danger ml-2" (click)="reset()">Reset</button>
            <button class="btn btn-warning ml-2" (click)="publishPuzzles()">Publish</button>
        </div>
        <div *ngIf="!loading" class="scroll" (scroll)="onScroll($event)">
            <table class="table table-striped table-puzzles-categories">
                <thead>
                    <tr>
                        <th scope="col">Category</th>
                        <th scope="col">To Publish</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="pointer">
                            <button class="btn btn-info ml-2" (click)="changeCategory('MINI')">MINI</button>
                        </td>
                        <td>
                            <input class="form-control ml-2 input-puzzle-number ml-auto" type="number" [value]="puzzlesMiniLength" disabled />
                        </td>
                    </tr>
                    <tr>
                        <td class="pointer">
                            <button class="btn btn-info ml-2" (click)="changeCategory('MAXI')" type="number">MAXI</button>
                        </td>
                        <td>
                            <input class="form-control ml-2 input-puzzle-number ml-auto" type="number" [value]="puzzlesMaxiLength" disabled />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-12">
                <small class="pb-2 pt-2" style="font-size: 110%; display: block;">Toogle Checkbox to select</small>
            </div>
        </div>
        
        <div *ngIf="!loading" class="scroll" (scroll)="onScroll($event)">
            <div *ngFor="let puzzle of puzzles; let index = index" class="col-12"
                [ngClass]="{'done': puzzle.status==2, 'open': puzzle.status != 2,  'selected': puzzle.selected}" style="border-bottom:1px solid grey">
                <div class="row">
                    <div style="display: flex;
                    align-self: center;
                    width: 35px;
                    margin-left: 20px;">
                        <input type="checkbox" [checked]="puzzle.selected" class="pointer" (click)="updatePuzzleCount($event, puzzle)" name="" id="">
                    </div>
                    <div class="col-1">
                        <p style="margin-top:15px;">{{index+1}}.</p>
                    </div>
                    <div class="col-3">
                        <p style="margin-top:15px;">{{puzzle.day | date:'dd.MM.yyyy'}}</p>
                    </div>
                    <div class="col-2">
                        <p style="margin-top:15px;">{{puzzle.status}}</p>
                    </div>
                    <div class="col-2">
                        <p style="margin-top:15px;">{{puzzle?.uuid | puzzlesCategory }}</p>
                    </div>
                    <div style="padding:5px;" class="col-2">
                        <button style="background-color: #EFEFEF;" (click)="openPuzzle(puzzle);" class="btn btn-default btn-block">Bearbeiten
                        </button>
                    </div>
                    <div class="col-2">
                    </div>
                </div>
            </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
<p *ngIf="loading">
    loading...
</p>




<!-- 
<div class="container py-5">
    <div class="row">
        <div class="col-12 mx-auto bg-white rounded shadow">

            <div class="table-responsive">
                <table class="table table-fixed">
                    <thead>
                        <tr>
                            <th scope="col" class="col-3">#</th>
                            <th scope="col" class="col-3">Day</th>
                            <th scope="col" class="col-3">Uuid</th>
                            <th scope="col" class="col-3"></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="!loading">
                        <tr *ngFor="let puzzle of puzzles; let index = index"
                            [ngClass]="{'done': puzzle.status==2, 'open': puzzle.status != 2}">
                            <td class="col-1">
                                <input type="checkbox" name="" id="">
                            </td>
                            <td class="col-2">
                                <p style="margin-top:15px;">{{puzzle.day | date:'dd.MM.yyyy'}}</p>
                            </td>
                            <td class="col-2">
                                <p style="margin-top:15px;">{{puzzle.uuid}}</p>

                            </td>
                            <td class="col-2">
                                <button (click)="openPuzzle(puzzle);" class="btn btn-default btn-block">Bearbeiten
                                </button>

                            </td>
                            <td class="col-3">
                                <button (click)="pushPuzzle(puzzle)" class="btn btn-danger">
                                    Veröffentlichen als Premium
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div> -->

<!-- <th scope="row" class="col-2">{{index}}</th>                            -->