import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DailyPackagesService } from 'src/app/daily-packages.service ';
import { EditPuzzleModalComponent } from 'src/app/dashboard/dashboard/puzzle-view/puzzle-edit/edit-puzzle-modal/edit-puzzle-modal.component';
import { PuzzlesService } from 'src/app/dashboard/dashboard/puzzle-view/puzzles.service';
import { PackageAddPuzzleModalComponent } from 'src/app/pages/puzzles-publishing/publishing-calendar/package-setting-modal/packges-puzzles-config-modal/package-add-puzzle-modal/package-add-puzzle-modal.component';
import { PackageReplacePuzzleComponent } from 'src/app/pages/puzzles-publishing/publishing-calendar/package-setting-modal/packges-puzzles-config-modal/package-replace-puzzle/package-replace-puzzle.component';
import { DailyPackageConfig } from 'src/app/pages/puzzles-publishing/publishing-calendar/package-setting-modal/packges-puzzles-config-modal/packges-puzzles-config-modal.component';
import { puzzleData } from 'src/app/pages/puzzles-publishing/publishing-calendar/publishing-calendar.component';
import { PuzzlesPublishingService } from 'src/app/pages/puzzles-publishing/puzzles-publishing.service';

@Component({
  selector: 'app-puzzles-edit-list',
  template: `
    <button nz-button [nzSize]="'small'" nzType="primary" (click)="addPuzzle()" >Add Puzzle<i class="icon-add" nz-icon nzType="file-add" nzTheme="outline"></i></button> 
    <p *ngIf="source.puzzlesNumber">Deafault Values Puzzles:     
        <span nz-typography nzType="warning">{{source?.puzzlesNumber}}</span>
    </p> 
    <p *ngIf="source.puzzlesNumber">Current Number of Puzzles:      
      <span nz-typography nzType="success">{{source.dailyPackage[source.profilType][source.puzzlesCategory].length}}</span>
    </p> 
    <nz-list nzGrid>
      <div nz-row [nzGutter]="16">
        <div nz-col [nzXXl]="6" [nzXl]="6" [nzLg]="6" [nzMd]="6" [nzSm]="6" [nzXs]="6" *ngFor="let item of data; trackBy: trackByUuid">
          <nz-list-item nzPopoverPlacement="bottom"  nz-popover [nzPopoverContent]="contentTemplate" (nzExpandChange)="loadPuzzle(item)">
            <div style="background-color: white;" class="ant-card-head ng-star-inserted">
              <div class="ant-card-head-wrapper">
                <div class="ant-card-head-title ng-star-inserted">{{item.uuid}}
                  <nz-button-group [nzSize]="'small'">
                    <button nz-button nzType="dashed" (click)="replacePuzzle(item)" class="btn-replace"><i nz-icon nzType="reload"></i></button>
                    <button nz-button (click)="viewPuzzle(item)" class="btn-remove"><i nz-icon nzType="expand-alt" nzTheme="outline"></i></button>
                    <button nz-button nzType="danger"(click)="removePuzzle(item)" class="btn-remove"><i nz-icon nzType="delete"></i></button>
                  </nz-button-group>
                </div>
              </div>
            </div>
          </nz-list-item>
          <ng-template #contentTemplate>
            <app-puzzle-preview-pop-over  [puzzleId]="item._id"></app-puzzle-preview-pop-over>
        </ng-template>
        </div>
      </div>
    </nz-list>
  `,
  styles: [
    `
     nz-card {
        padding: 0px;
      }

    :host ::ng-deep nz-card > div.ant-card-body {
        padding: 0
     }

     :host ::ng-deep nz-list > nz-spin {
        background-color: rgb(41 40 40 / 9%);
        padding: 15px;
     }

     .icon-add {
        position: relative;
        top: -3px;
        left: 4px;
     }
    
    `
  ]
})
export class PuzzlesEditListComponent {

  source: any = {}


  @Input('dailyPackage') set dailyPackage(source: DailyPackageConfig) {
    this.source = source;
    this.data = source.dailyPackage[source.profilType][source.puzzlesCategory]
  }


  data: puzzleData[] = [];


  loadPuzzle(puuzle) {
    console.log(puuzle, 'puuzle')
  }

  isVisible = false;
  isConfirmLoading = false;

  constructor(
    private modalService: NzModalService, private puzzleService: PuzzlesService,
    private service: PuzzlesPublishingService,
    private servicePackage: DailyPackagesService,
    private modalSerivce: NgbModal,
  ) { }

  trackByUuid(item: any) {
    return item.uuid
  }

  handleOk(): void {
    this.isConfirmLoading = true;
    setTimeout(() => {
      this.isVisible = false;
      this.isConfirmLoading = false;
    }, 3000);
  }

  viewPuzzle(item: puzzleData) {
    this.puzzleService.getSinglePuzzle(item._id)
      .subscribe(
        (puzzle) => {
          const puzzleModal = this.modalSerivce.open(EditPuzzleModalComponent, {
            size: 'xl'
          })
          puzzleModal.componentInstance.editPuzzle = puzzle;
          puzzleModal.componentInstance.editPuzzle.points = puzzle.totalGrid;
          puzzleModal.componentInstance.puzzle = puzzle
        })
  }


  handleCancel(): void {
    this.isVisible = false;
  }

  addPuzzle() {
    const modal = this.modalService.create({
      nzTitle: 'Search For Puzzle To Add',
      nzContent: PackageAddPuzzleModalComponent,
      nzWidth: '800px',
      nzOnOk: (component) => {
        console.log(component, 'ready')
        // console.log(this.source.dailyPackage[this.source.profilType][this.source.puzzlesCategory], currentEditedPuzzle._id, 'hii');
        let puzzles = this.source.dailyPackage[this.source.profilType][this.source.puzzlesCategory]

        return this.service.getPuzzleByUuid(component.data).toPromise()
          .then(
            (puzzleArr) => {
              console.log(component.data, 'GETTEDDATA')
              const mappedPuzzles = puzzleArr.map((p) => ({ _id: p._id, uuid: p.uuid }))
              puzzles = [...puzzles, ...mappedPuzzles]
              this.source.dailyPackage[this.source.profilType][this.source.puzzlesCategory] = puzzles;
              return this.servicePackage.updateConfig(this.source.dailyPackage).toPromise()
                .then(r => {
                  console.log('result')
                  this.data = this.source.dailyPackage[this.source.profilType][this.source.puzzlesCategory] = puzzles;
                })
            }
          )
      }

    })

    if (modal.componentInstance) {
      modal['componentInstance']['category'] = 'MINI';
    }
  }

  removePuzzle(puzzle) {
    console.log('readasd')
    this.modalService.confirm(
      {
        nzTitle: 'Delete Puzzle',
        nzContent: `Are you sure for deleting ${puzzle.uuid}`,
        nzOnOk: () => {
          console.log(this.source.dailyPackage[this.source.profilType][this.source.puzzlesCategory], puzzle._id, 'hii');

          let puzzles = this.source.dailyPackage[this.source.profilType][this.source.puzzlesCategory]

          puzzles = puzzles.filter(p => p._id !== puzzle._id)

          this.source.dailyPackage[this.source.profilType][this.source.puzzlesCategory] = puzzles;

          console.log(this.source.dailyPackage, 'result')

          return this.servicePackage.updateConfig(this.source.dailyPackage).toPromise()
            .then(r => {
              console.log('result')
              this.data = this.source.dailyPackage[this.source.profilType][this.source.puzzlesCategory] = puzzles;
            })
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          // }).catch(() => console.log('Oops errors!'))
        }
      }
    )

  }

  replacePuzzle(currentEditedPuzzle: puzzleData) {
    const modal = this.modalService.create({
      nzTitle: 'Replace Puzzle',
      nzContent: PackageReplacePuzzleComponent,
      nzWidth: '500px',
      nzOnOk: (component) => {

        // console.log(this.source.dailyPackage[this.source.profilType][this.source.puzzlesCategory], currentEditedPuzzle._id, 'hii');

        let puzzles = this.source.dailyPackage[this.source.profilType][this.source.puzzlesCategory]

        return this.service.getPuzzleByUuid([component.data[0]]).toPromise()
          .then(
            (puzzleArr) => {
              let puzzle = puzzleArr[0]
              console.log(puzzle, 'GETTED BY UUID')
              console.log(component.data[0], 'GETTEDDATA')
              puzzles = puzzles.map(p => {
                console.log()
                if (p.uuid === currentEditedPuzzle.uuid) {
                  return { _id: puzzle._id, uuid: puzzle.uuid }
                } else {
                  return p
                }
              })
              this.source.dailyPackage[this.source.profilType][this.source.puzzlesCategory] = puzzles;

              return this.servicePackage.updateConfig(this.source.dailyPackage).toPromise()
                .then(r => {
                  console.log('result')
                  this.data = this.source.dailyPackage[this.source.profilType][this.source.puzzlesCategory] = puzzles;
                })
            }
          )
      }

    })
    if (modal.componentInstance) {
      this.service.getPuzzlesUuids(this.source.puzzlesCategory).subscribe(c => {
        const suggestions = c.map(c => c.uuid)

        if (modal.componentInstance) {
          modal.componentInstance.suggestions = suggestions;
        }

      })
      modal.componentInstance.currentEditedPuzzle = currentEditedPuzzle;
    }
  }
}
