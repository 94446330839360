import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { PagesComponent } from './pages/layout/layout.component';
import { LayoutModule } from './pages/layout/layout.module';
import { Login2Component} from './auth/login2/login2.component'

const routes: Routes = [
  { path: '', component: Login2Component },
  { path: 'dashboard', component: DashboardComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    AuthModule,
    LayoutModule,
    DashboardModule

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
