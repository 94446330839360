import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-pages',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class PagesComponent {

  isCollapsed = false;
  constructor(private auth: AuthService, private route: Router, private toastr: ToastrService,
  ) { }

  signOut() {
    this.auth.logout()
      .subscribe(
        () => {
          this.route.navigate(['/']);
        },
        () => {
          this.toastr.error('Somtehing went wrong');
        }
      );
  }

}
