<div>
    <div class="form-group form-inline inline-block  mr-2">
        search by id: <input class="form-control ml-2 " type="text" [(ngModel)]="searchId" name="searchId"
            (keyup.enter)="search('uuid')" />
    </div>

    <div class="form-group form-inline inline-block  mr-2">
        search by publishDate: <input placeholder="label" type="date" (change)="search('day')" [(ngModel)]="searchPublishDate" >
    </div>
    <button class="btn btn-danger ml-2" (click)="reset()">Reset</button>
    <button class="btn btn-info ml-2" (click)="search('uuid')">Search</button>
</div>

<div *ngIf="!loading" class="scroll" (scroll)="onScroll($event)">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">uuid</th>
                <th scope="col">Category</th>
                <th scope="col">Punkte</th>
                <th scope="col">Publish Date</th>
                <th scope="col">Status</th>
                <th scope="col">Edit</th>
            </tr>
        </thead>
        <tbody>
            <tr class="selected" *ngFor="let puzzle of puzzles; index as i">
                <th scope="row">{{ i + 1 }}</th>
                <td>
                    {{puzzle?.uuid }}
                </td>
                <td>
                    {{puzzle?.uuid | puzzlesCategory}}
                </td>
                <td>
                    {{puzzle.points}}
                </td>
                <td>
                    {{puzzle.day | date: 'medium' }}
                </td>
                <td>
                    {{puzzle.status}}
                </td>
                <td>
                    <div style="padding:5px" class="col-2">
                        <button (click)="openPuzzle(puzzle);" class="btn btn-default btn-block">Bearbeiten
                        </button>
                    </div>
                </td>

            </tr>
        </tbody>
    </table>
</div>
<p *ngIf="loading">
    Loading...
</p>