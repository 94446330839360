import { Component, Input, OnInit } from '@angular/core';
import { AccountDefaultValues } from '../../../default-values-settings/default-values-settings.component';

export type category = "Lite" | "Registered" | 'Premium'

@Component({
  selector: 'app-puzzles-values-view',
  templateUrl: './puzzles-values-view.component.html',
  styleUrls: ['./puzzles-values-view.component.scss']
})
export class PuzzlesValuesViewComponent {

  display: any = [
    {
      type: 'Lite',
      maxi: 25,
      mini: 25,
      total: 50
    },
  ];

  @Input() set category(category: category) {
    this.display = [this.listOfAccountsDefaultValues.find( s => s.type === category)]
  }  
      
  @Input() listOfAccountsDefaultValues: AccountDefaultValues[] = [
    {
      type: 'Lite',
      maxi: 25,
      mini: 25,
      total: 50
    },
    {
      type: 'Registered',
      maxi: 50,
      mini: 50,
      total: 100
    },
    {
      type: 'Premium',
      maxi: 50,
      mini: 50,
      total: 99
    }
  ];

}
