import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'puzzlesCategory',
})
export class PuzzlesCategoryPipe implements PipeTransform {

  transform(puzzleUuid: string | undefined): string {

    if (puzzleUuid) { 

      if (puzzleUuid.indexOf('PMA') !== -1) {
        return 'MAXI'
      }
			if (puzzleUuid.indexOf('PMI') !== -1) {
        return 'MINI'
			}
			if (puzzleUuid.indexOf('DMA') !== -1) {
        return 'MAXI'
			}
			if (puzzleUuid.indexOf('DMI') !== -1) {
        return 'MINI'
			}

    }

    return '';
  }

}
