<div class="mb-4">
    <h3 class="segment-select">3. Delivery Schedule</h3>
    <div class="sheduled-container p-4 card">
        <div class="left-column">
            <div class="sheduled-options">
                <span class="sc-bQduHL lkjUel">When should this message start sending?<div class="sc-kEYyzF cWdXGi"
                        aria-label="More information">
                    </div>
                </span>
                <div class="sc-chAAoq bBREwo">
                    <input type="radio" (change)="changeInfoSheduled(false, 'Message will send immediately in each user\'s timezone') " id="send-at-immediately" name="send-at"
                        class="sc-hycgNl ixrhFJ">
                    <div class="label-option">
                        <label for="send-at-immediately">
                            <span>Immediately</span>
                        </label>
                    </div>
                </div>
                <div class="sc-kGeDwz cnIfiu">
                    <div class="sc-chAAoq bBREwo"><input (change)="changeInfoSheduled(true, 'Message will start sending at selected time') "
                            [checked]="checkedSpecificTime" type="radio" id="send-at-queue-later" name="send-at"
                            class="sc-hycgNl ixrhFJ">
                        <div class="label-option"><label for="send-at-queue-later"><span>Specific Time</span></label>
                        </div>
                        <div *ngIf="checkedSpecificTime">
                            date: <input class="form-group form-inline inline-block  mr-2" type="datetime-local"
                                id="meeting-time" name="meeting-time" value="2018-06-12T19:30" min="2018-06-07T00:00"
                                max="2018-06-14T00:00">
                        </div>
                    </div>
                </div>
            </div>
            <div class="sheduled-optimizations">
                <span class="sheduled-optimizations-header">Per user optimization?
                </span>

                <div class="dasdsadasd">
                    <input (change)="changeInfoOptimization(false, 'Message will send at the same time') " (change)="customTimePerTimeZone = false" type="radio" id="send-at-immediately" name="send-at"
                        class="sc-hycgNl ixrhFJ">
                    <div class="label-option">
                        <label for="send-at-immediately">
                            <span>Send to everyone at the same time</span>
                        </label>
                    </div>
                </div>

                <div class="dasdasd">
                    <input (change)="changeInfoOptimization(false, 'Message will send at the time when each user is predicted to be active')" type="radio" id="send-at-immediately" name="send-at"
                        class="sc-hycgNl ixrhFJ">
                    <div class="label-option">
                        <label for="send-at-immediately">
                            <span>Intelligent delivery (recommended)</span>
                        </label>
                    </div>
                </div>

                <div class="label-option">
                    <input (change)="changeInfoOptimization(true, 'Message will send the next time it in selected each user\'s timezone')" [checked]="customTimePerTimeZone" type="radio"
                        id="send-at-immediately" name="send-at" class="sc-hycgNl ixrhFJ">
                    <div class="label-option">
                        <label for="send-at-immediately">
                            <span>Custom time per user timezone</span>
                        </label>
                    </div>
                    <div *ngIf="customTimePerTimeZone">
                        time: <input class="form-group form-inline inline-block  mr-2" type="time" id="appt" name="appt" min="09:00" max="00:00" required>
                    </div>
                </div>

            </div>
        </div>
        <div class="right-column">
            <span class="message-span">
                <svg class="icon-info" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
                    <g clip-path="url(#clip0)" fill="currentColor">
                        <path
                            d="M8 0a8 8 0 108 8 8.009 8.009 0 00-8-8zm.167 3.333a1 1 0 110 2 1 1 0 010-2zm1.5 9H7A.667.667 0 117 11h.5a.167.167 0 00.167-.167v-3a.167.167 0 00-.167-.166H7a.667.667 0 110-1.334h.667A1.333 1.333 0 019 7.667v3.166a.167.167 0 00.167.167h.5a.666.666 0 110 1.333z">
                        </path>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <path fill="#fff" d="M0 0h16v16H0z"></path>
                        </clipPath>
                    </defs>
                </svg>
                {{messageSheduled}}
                {{messageOptimization}}
            </span>
        </div>
    </div>
</div>