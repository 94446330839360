<div class="table-width">
    <nz-table [nzFrontPagination]="false" #basicTable [nzData]="listOfAccountsDefaultValues">
        <thead>
            <tr>
                <th>TYPE</th>
                <th>MAXI</th>
                <th>MINI</th>
                <th>TOTAL</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of display">
                <td>{{data.type}}</td>
                <td>
                    <nz-input-number [nzDisabled]="true" [(ngModel)]="data.maxi" [nzMin]="1" [nzMax]="1000" [nzStep]="1"></nz-input-number>
                </td>
                <td>
                    <nz-input-number [nzDisabled]="true" [(ngModel)]="data.mini" [nzMin]="1" [nzMax]="1000" [nzStep]="1"></nz-input-number>
                </td>
                <td>
                    <nz-input-number [nzDisabled]="true" [(ngModel)]="data.total" [nzMin]="1" [nzMax]="1000" [nzStep]="1">
                    </nz-input-number>
                </td>
            </tr>
        </tbody>
    </nz-table>
</div>