import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PuzzlesService } from '../../../puzzles.service';

@Component({
  selector: 'app-edit-puzzle-bin-confirm-modal',
  template: `
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">
              <h2>
                  Fügen Sie hier Ihren Kommentar zum fehlerhaften Rätsel ein:
              </h2>
          </h4>
          <button type="button" class="close" aria-label="Close" (click)="close()">
              <span aria-hidden=true>&times;</span>
          </button>
      </div>
      <div class="modal-body">
          <textarea [(ngModel)]="puzzleComment"name="" id="" cols="30" rows="10"></textarea>
          <div style="margin-top:20px">
              <button class="btn btn-success btn-block" (click)="setPuzzleStatusToBin()">Kommentar speichern</button>
          </div>
      </div>
  `, 
  styles: [`
    .modal-body {
      text-align: center;
    }

    textarea {
      width: 100%
    }

  `]
})
export class EditPuzzleBinConfirmModalComponent implements OnInit {


  puzzleComment = ''

  @Input() editPuzzle: any = {};
  @Input() editPuzzlePoints = {};
  @Input() puzzle = {}

  constructor(
    private modalService: NgbActiveModal,
    private toastr: ToastrService,
    private puzzleService: PuzzlesService) { }

  ngOnInit() { }

  close() {
    this.modalService.dismiss()
  }

  setPuzzleStatusToBin() {
    if (this.puzzleComment.length < 5) {
      this.toastr.error('Puzzle comment shoud have at least 5 chars');
    }
    this.editPuzzle.status = -1;
    this.editPuzzle.complainComment = this.puzzleComment
    this.puzzleService
      .updatePuzzle(this.editPuzzle._id, this.editPuzzle)
      .subscribe(
        () => {
          this.toastr.success('Saved puzzle')
          this.modalService.close()
        }, (err => {
          this.toastr.error('Something went wrong')
        }))
  }

}
