import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablePuzzleFilterPipe } from '../table-puzzle-filter.pipe';
import { TablePuzzleComponent } from './table-puzzle/table-puzzle.component';
import { FormsModule } from '@angular/forms';
import { PuzzlesService } from './puzzles.service';
import { PuzzleViewComponent } from './puzzle-view.component';
import { PuzzleEditModule } from './puzzle-edit/puzzle-edit.module';

@NgModule({
  declarations: [
    TablePuzzleFilterPipe,
    TablePuzzleComponent,
    PuzzleViewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PuzzleEditModule,
  ],
  providers: [PuzzlesService],
  exports: [TablePuzzleComponent, TablePuzzleFilterPipe, PuzzleViewComponent, PuzzleEditModule]
})


export class PuzzleViewModule { }
