<div #containerRef>
    <ngx-charts-pie-chart
    [view]="[containerRef.offsetWidth, 375]"
    [scheme]="colorScheme"
    [results]="single"
    [gradient]="gradient"
    [legend]="showLegend"
    [legendPosition]="legendPosition"
    [labels]="showLabels"
    [doughnut]="isDoughnut"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)"
    >
  </ngx-charts-pie-chart>
</div>
