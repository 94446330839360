import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PuzzleSearchViewComponent } from './puzzle-search-view/puzzle-search-view.component';
import { FormsModule } from '@angular/forms';
import { PuzzleSearchService } from './puzzle-search.service';
import { SharedPipesModule } from 'src/app/shared/shared-pipes/shared-pipes.module';

@NgModule({
  declarations: [PuzzleSearchViewComponent],
  imports: [
    CommonModule,
    SharedPipesModule,
    FormsModule
  ],
  exports: [
    PuzzleSearchViewComponent
  ],
  providers: [PuzzleSearchService]
})
export class PuzzleSearchViewModule { }
