import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaginateData } from '../users-view/users-table/users-table.component';
import { NotificationData } from './notifications-view/notifications-view.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  
  backendUrl = environment.backendUrl;

  constructor(private http: HttpClient) { }

  getAllNotifications(paginateData: PaginateData): Observable<NotificationData> {
    return this.http.get<NotificationData>(`${this.backendUrl}/api/notificationsView/notifications`, {
      params: {
        page: `${paginateData.page}`,
        pageSize: `${paginateData.pageSize}`
      }
    })
  }

  // searchUsers(searchCategory: searchCategory, typing: string): Observable<any> {
  //   return this.http.get(`${this.backendUrl}/api/usersView/search`, {
  //     params: {
  //       searchCategory: searchCategory,
  //       typed: typing
  //     }
  //   })
  // }

  // getUserInfo(_id: string): Observable<any> {
  //   return this.http.get(`${this.backendUrl}/api/usersView/userInfo`, {
  //     params: {
  //       userId: _id,
  //     }
  //   })
  // }

  // deleteUser(_id: string): Observable<any> {
  //   return this.http.delete(`${this.backendUrl}/api/usersView/deleteUser/${_id}`)
  // }

}
