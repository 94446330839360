import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NzMentionComponent } from 'ng-zorro-antd/mention/public-api';
import { puzzleData } from '../../../publishing-calendar.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  template: `
      <p>Current Editied Puzzle To Change     
      <span nz-typography nzType="success">{{currentEditedPuzzle.uuid}}</span>      
      <nz-mention #mention (nzOnSelect)="onSearchChange($event)" (nzOnSelect)="onSearchChange($event)" [nzPrefix]="'@'" [nzSuggestions]="suggestions">
        <textarea #mention nz-input [nzAutosize]="{ minRows: 1, maxRows: 1 }" [(ngModel)]="inputValue" nzMentionTrigger> </textarea>
    </nz-mention>
    <p>CHANGE TO </p>    
    <nz-list nzGrid>
  <div nz-row [nzGutter]="16">
    <div nz-col [nzXXl]="12" [nzXl]="12" [nzLg]="12" [nzMd]="12" [nzSm]="12" [nzXs]="12" *ngFor="let item of data">
      <nz-list-item>
        <div style="background-color: white;" class="ant-card-head ng-star-inserted">
          <div class="ant-card-head-wrapper">
            <div class="ant-card-head-title ng-star-inserted">{{item}}
              <nz-button-group [nzSize]="'small'">
                <button nz-button nzType="dashed" class="btn-replace"><i nz-icon nzType="reload"></i></button>
                <button nz-button nzType="danger" class="btn-remove"><i nz-icon nzType="delete"></i></button>
              </nz-button-group>
            </div>
          </div>
        </div>
      </nz-list-item>
    </div>
  </div>
</nz-list>
  `
})
export class PackageReplacePuzzleComponent  {

  @ViewChild('mention') mention;
  data: string[] = []
  inputValue?: string;
  @Input() suggestions = ['afc163', 'benjycui', 'yiminghe', 'RaoHai', '中文', 'にほんご'];
  @Input() currentEditedPuzzle: puzzleData =  {_id: '', uuid: ''} 


  onSearchChange(d): void {
    this.data = (this.mention as NzMentionComponent).getMentions().map( m => m.replace('@', ""))
    // this.suggestions = prefix === '@' ? this.users : this.tags;
  }

}
