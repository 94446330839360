import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  template: `
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            <h2>Rätsel bearbeiten<span *ngIf="puzzle.daily==true"> MAXI</span><span *ngIf="puzzle.dailymini==true">
                    MINI</span></h2>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden=true>&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngClass]="{'disabled': disabled}">
        <div class="row">
            <div class="col">
                <app-edit-puzzle-cross-word [puzzle]="puzzle" [question_num]="question_num" ></app-edit-puzzle-cross-word>
            </div>
            <div class="col">
                    <app-edit-puzzle-answers [disabled]="disabled" [questions]="questions" [puzzle]="puzzle" (onNextQuestionNum)="setNextQuestionNum($event)"></app-edit-puzzle-answers>
                </div>
            </div>
        </div>
    <app-edit-puzzle-save class="row" (onPuzzleMovedBin)="onPuzzleMovedBin()" [disabled]="disabled" [editPuzzle]="editPuzzle"></app-edit-puzzle-save>            
  `  
  ,
  styles: [`
  
    .disabled {
      opacity: 0.4;
    }
  
  `]
})
export class EditPuzzleModalComponent implements OnInit {

  questions: any = []
  question_num = 1;

  @Input("puzzle") puzzle: any;
  @Input("editPuzzle") editPuzzle: any;
  @Input("disabled") disabled: false = false;

  constructor(
    private modalServiceA: NgbActiveModal,) {
      
     }

  ngOnInit() {
    this.puzzle.grid
      .forEach((cordinatesArr: Array<any>) => {
        cordinatesArr = cordinatesArr
          .filter(cordinate => {
            return cordinate.question_vertical !== null || cordinate.question_horizontal !== null;
          })

        this.questions.push(cordinatesArr);
      })
  }

  close() {
    this.modalServiceA.close()
  }

  // updatePuzzle(puzzle: Puzzle) {
  //   this.puzzleService
  //     .updatePuzzle(puzzle._id, puzzle)
  //     .subscribe(
  //       (s) => {
  //         this.modalServiceA.close()
  //       }, (err => {
  //         console.log(err, 'err');
  //       }))
  // }

  createDate(day1: string, day2: any) {
    var myArray = day1.split('.')
    day2.day = new Date(myArray[2] + "-" + myArray[1] + '-' + myArray[0])
  }

  // updateQuestion(question: any, x: number, y: number) {
  //   question.xo = x;
  //   question.yo = y;
  //   this.puzzleService
  //     .updateQuestion(this.puzzle._id, question)
  //     .subscribe(
  //       (s) => {
  //         console.log(s, 'succes');
  //       },
  //       (err => {
  //         console.log(err, 'err');
  //       })
  //     )
  // }

  // openPuzzleBinConfirmModal(editPuzzle: Puzzle) {
  //   this.puzzleService.getSinglePuzzle(editPuzzle._id)
  //     .subscribe(
  //       (puzzle) => {
  //         const puzzleModal = this.modalService.open(EditPuzzleBinConfirmModalComponent, {
  //           size: 'xl'
  //         })
  //         puzzleModal.componentInstance.editPuzzle = editPuzzle;
  //         puzzleModal.componentInstance.editPuzzle.points = editPuzzle.totalGrid;
  //         puzzleModal.componentInstance.puzzle = puzzle
  //         puzzleModal.result.then(r => { 
  //           this.modalServiceA.dismiss()
  //         }).catch( s => console.log(s)
  //          )
  //       })

  // }

  setNextQuestionNum(questionNum: number) {
    this.question_num = questionNum;
  }

  onPuzzleMovedBin() {
    this.modalServiceA.dismiss()
  }

  // checkNull(property: string) {
  //   return !!property;
  // }

}
