import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddSegementsModalComponent } from '../add-segements-modal/add-segements-modal.component';

@Component({
  selector: 'app-users-segments',
  templateUrl: './users-segements.component.html',
  styleUrls: ['./users-segements.component.scss']
})
export class UsersSegementsComponent {

  segmentsDisplay: any[] = []
  segmentsCopy: string[] = []

  @Input() set segments(s: string[]) {
    this.segmentsDisplay = s;
    this.segmentsCopy = s;
  }  
  
  @Input() header:string = ''

  constructor(private modalService: NgbModal, private toastr: ToastrService) { }

  removeSegment(segment: string) {
    this.segmentsDisplay = this.segmentsDisplay.filter( s =>  s !== segment)
  }

  opeadAddSegmentModal() {
    const modal = this.modalService.open(AddSegementsModalComponent)
    modal.componentInstance.segments = this.segmentsCopy
    modal.result.then((segment) => {      
      this.segmentsDisplay.findIndex( s => s === segment) === -1
      ?  this.segmentsDisplay.push(segment)
      :  this.toastr.error('This segment already exist')
      
    })
  }

}
