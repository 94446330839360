<div class="example">
  <nz-spin [nzSpinning]="isLoading" nzSimple></nz-spin>
</div>
<nz-tabset *ngIf="!isLoading">
  <nz-tab (nzSelect)="changeCategory('maxi')" nzTitle="maxi">
    <app-puzzles-edit-list [dailyPackage]="source"></app-puzzles-edit-list>
  </nz-tab>
  <nz-tab (nzSelect)="changeCategory('mini')" nzTitle="mini">
    <app-puzzles-edit-list [dailyPackage]="source"></app-puzzles-edit-list>
  </nz-tab>
</nz-tabset>