import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UsersService } from '../users.service';

export interface User {
  name: string
  _id: string
  memberShip: number
  email: string
  date: Date
  displayName? : string

}

@Component({
  selector: 'app-users-layout',
  template: `
    <span class="col-12" [ngSwitch]="!!chosenUser">
    <app-user-info [user]="chosenUser" (onOpenUserTable)="onOpenUserTable()" class="col-8" (nextUser)="setUser($event)" *ngSwitchCase="true"></app-user-info>
    <app-users-table *ngSwitchDefault (nextUser)="setUser($event)" class="col-8"></app-users-table>
    </span>
  `
})
export class UsersLayoutComponent {

  chosenUser: any;

  // public users$: Observable<User[]> = new Observable()
  public users$: User[] = []

  constructor(private usersService: UsersService) {
  }

  setUser(user: any) {
    this.chosenUser = user;
  }

  onOpenUserTable() {
    this.chosenUser = [];
  }

}
