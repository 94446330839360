import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-layout',
  template: `
  <app-default-values></app-default-values>
  <div>
    <app-publishing-calendar></app-publishing-calendar>
  </div>
  `,
  styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
