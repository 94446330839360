const puzzle =  {
  "_id": "5dec2f00de95855cf911cf35",
  "free": true,
  "package": 9999,
  "grid2": [
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf40",
        "x": 1,
        "y": 1,
        "answer": "A",
        "question_num": 1,
        "question_vertical": "englisch, französisch: Alter",
        "question_horizontal": "eh. türk. Titel für Offiziere u. Beamte"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf3f",
        "x": 2,
        "y": 1,
        "answer": "G",
        "question_num": 2,
        "question_vertical": "Violinenspieler",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf3e",
        "x": 3,
        "y": 1,
        "answer": "A",
        "question_num": 3,
        "question_vertical": "US-Militärsender (Abk.)",
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf3d",
        "x": 4,
        "y": 1,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf3c",
        "x": 5,
        "y": 1,
        "answer": "E",
        "question_num": 4,
        "question_vertical": "deutsche Vorsilbe",
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf3b",
        "x": 6,
        "y": 1,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf3a",
        "x": 7,
        "y": 1,
        "answer": "R",
        "question_num": 5,
        "question_vertical": "Siebenschläfer",
        "question_horizontal": "Schauspieleraufgabe"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf39",
        "x": 8,
        "y": 1,
        "answer": "O",
        "question_num": 6,
        "question_vertical": "Schalterstellung (engl.)",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf38",
        "x": 9,
        "y": 1,
        "answer": "L",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf37",
        "x": 10,
        "y": 1,
        "answer": "L",
        "question_num": 7,
        "question_vertical": "kraftlos, lasch, matt",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf36",
        "x": 11,
        "y": 1,
        "answer": "E",
        "question_num": 8,
        "question_vertical": "australischer Laufvogel",
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf4b",
        "x": 1,
        "y": 2,
        "answer": "G",
        "question_num": 9,
        "question_vertical": "Violinenspieler",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf4a",
        "x": 2,
        "y": 2,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf49",
        "x": 3,
        "y": 2,
        "answer": "F",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf48",
        "x": 4,
        "y": 2,
        "answer": "A",
        "question_num": 10,
        "question_vertical": "griechische Vorsilbe: Stern",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf47",
        "x": 5,
        "y": 2,
        "answer": "N",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf46",
        "x": 6,
        "y": 2,
        "answer": "G",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf45",
        "x": 7,
        "y": 2,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf44",
        "x": 8,
        "y": 2,
        "answer": "N",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf43",
        "x": 9,
        "y": 2,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf42",
        "x": 10,
        "y": 2,
        "answer": "A",
        "question_num": 11,
        "question_vertical": null,
        "question_horizontal": "kurz für: an dem"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf41",
        "x": 11,
        "y": 2,
        "answer": "M",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf56",
        "x": 1,
        "y": 3,
        "answer": "E",
        "question_num": 12,
        "question_vertical": "US-Militärsender (Abk.)",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf55",
        "x": 2,
        "y": 3,
        "answer": "I",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf54",
        "x": 3,
        "y": 3,
        "answer": "N",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf53",
        "x": 4,
        "y": 3,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf52",
        "x": 5,
        "y": 3,
        "answer": "T",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf51",
        "x": 6,
        "y": 3,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf50",
        "x": 7,
        "y": 3,
        "answer": "L",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf4f",
        "x": 8,
        "y": 3,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf4e",
        "x": 9,
        "y": 3,
        "answer": "U",
        "question_num": 13,
        "question_vertical": "Papstname",
        "question_horizontal": "größte europäische Eule"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf4d",
        "x": 10,
        "y": 3,
        "answer": "H",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf4c",
        "x": 11,
        "y": 3,
        "answer": "U",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf61",
        "x": 1,
        "y": 4,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf60",
        "x": 2,
        "y": 4,
        "answer": "G",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf5f",
        "x": 3,
        "y": 4,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf5e",
        "x": 4,
        "y": 4,
        "answer": "T",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf5d",
        "x": 5,
        "y": 4,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf5c",
        "x": 6,
        "y": 4,
        "answer": "A",
        "question_num": 14,
        "question_vertical": "Brandrückstand",
        "question_horizontal": "Warnsignalruf"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf5b",
        "x": 7,
        "y": 4,
        "answer": "L",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf5a",
        "x": 8,
        "y": 4,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf59",
        "x": 9,
        "y": 4,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf58",
        "x": 10,
        "y": 4,
        "answer": "M",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf57",
        "x": 11,
        "y": 4,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf6c",
        "x": 1,
        "y": 5,
        "answer": "S",
        "question_num": 15,
        "question_vertical": "ein Brettspiel",
        "question_horizontal": "Teichblume"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf6b",
        "x": 2,
        "y": 5,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf6a",
        "x": 3,
        "y": 5,
        "answer": "E",
        "question_num": 16,
        "question_vertical": "biblischer Ort  im A.T. (2 Worte)",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf69",
        "x": 4,
        "y": 5,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf68",
        "x": 5,
        "y": 5,
        "answer": "O",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf67",
        "x": 6,
        "y": 5,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf66",
        "x": 7,
        "y": 5,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf65",
        "x": 8,
        "y": 5,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf64",
        "x": 9,
        "y": 5,
        "answer": "B",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf63",
        "x": 10,
        "y": 5,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf62",
        "x": 11,
        "y": 5,
        "answer": "A",
        "question_num": 17,
        "question_vertical": "Opferstein, -tisch",
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf77",
        "x": 1,
        "y": 6,
        "answer": "A",
        "question_num": 18,
        "question_vertical": null,
        "question_horizontal": "Fluss durch Florenz"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf76",
        "x": 2,
        "y": 6,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf75",
        "x": 3,
        "y": 6,
        "answer": "N",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf74",
        "x": 4,
        "y": 6,
        "answer": "O",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf73",
        "x": 5,
        "y": 6,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf72",
        "x": 6,
        "y": 6,
        "answer": "C",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf71",
        "x": 7,
        "y": 6,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf70",
        "x": 8,
        "y": 6,
        "answer": "B",
        "question_num": 19,
        "question_vertical": "Stadt an der Fulda",
        "question_horizontal": "Theatherstück von Brecht"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf6f",
        "x": 9,
        "y": 6,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf6e",
        "x": 10,
        "y": 6,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf6d",
        "x": 11,
        "y": 6,
        "answer": "L",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf82",
        "x": 1,
        "y": 7,
        "answer": "L",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf81",
        "x": 2,
        "y": 7,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf80",
        "x": 3,
        "y": 7,
        "answer": "D",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf7f",
        "x": 4,
        "y": 7,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf7e",
        "x": 5,
        "y": 7,
        "answer": "E",
        "question_num": 20,
        "question_vertical": "flüssiges Gewürz",
        "question_horizontal": "jemandem Achtung erweisen"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf7d",
        "x": 6,
        "y": 7,
        "answer": "H",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf7c",
        "x": 7,
        "y": 7,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf7b",
        "x": 8,
        "y": 7,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf7a",
        "x": 9,
        "y": 7,
        "answer": "N",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf79",
        "x": 10,
        "y": 7,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf78",
        "x": 11,
        "y": 7,
        "answer": "T",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf8d",
        "x": 1,
        "y": 8,
        "answer": "T",
        "question_num": 21,
        "question_vertical": null,
        "question_horizontal": "Stahlseil"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf8c",
        "x": 2,
        "y": 8,
        "answer": "R",
        "question_num": 22,
        "question_vertical": "Hautkrankheit bei Haustieren",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf8b",
        "x": 3,
        "y": 8,
        "answer": "O",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf8a",
        "x": 4,
        "y": 8,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf89",
        "x": 5,
        "y": 8,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf88",
        "x": 6,
        "y": 8,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf87",
        "x": 7,
        "y": 8,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf86",
        "x": 8,
        "y": 8,
        "answer": "B",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf85",
        "x": 9,
        "y": 8,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf84",
        "x": 10,
        "y": 8,
        "answer": "N",
        "question_num": 23,
        "question_vertical": "Karibik-Insel",
        "question_horizontal": "chem. Zeichen: Natrium"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf83",
        "x": 11,
        "y": 8,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf98",
        "x": 1,
        "y": 9,
        "answer": "A",
        "question_num": 24,
        "question_vertical": null,
        "question_horizontal": "gehoben für: Adler"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf97",
        "x": 2,
        "y": 9,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf96",
        "x": 3,
        "y": 9,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf95",
        "x": 4,
        "y": 9,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf94",
        "x": 5,
        "y": 9,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf93",
        "x": 6,
        "y": 9,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf92",
        "x": 7,
        "y": 9,
        "answer": "T",
        "question_num": 25,
        "question_vertical": "griechischer Buchstabe",
        "question_horizontal": "Domstadt an der Mosel"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf91",
        "x": 8,
        "y": 9,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf90",
        "x": 9,
        "y": 9,
        "answer": "I",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf8f",
        "x": 10,
        "y": 9,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf8e",
        "x": 11,
        "y": 9,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfa3",
        "x": 1,
        "y": 10,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfa2",
        "x": 2,
        "y": 10,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfa1",
        "x": 3,
        "y": 10,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfa0",
        "x": 4,
        "y": 10,
        "answer": "M",
        "question_num": 26,
        "question_vertical": "übel, schlecht",
        "question_horizontal": "Männerkurzname"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf9f",
        "x": 5,
        "y": 10,
        "answer": "I",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf9e",
        "x": 6,
        "y": 10,
        "answer": "C",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf9d",
        "x": 7,
        "y": 10,
        "answer": "H",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf9c",
        "x": 8,
        "y": 10,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf9b",
        "x": 9,
        "y": 10,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf9a",
        "x": 10,
        "y": 10,
        "answer": "V",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf99",
        "x": 11,
        "y": 10,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfae",
        "x": 1,
        "y": 11,
        "answer": "R",
        "question_num": 27,
        "question_vertical": "indischer Dichter † 1913",
        "question_horizontal": "lautlos"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfad",
        "x": 2,
        "y": 11,
        "answer": "U",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfac",
        "x": 3,
        "y": 11,
        "answer": "H",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfab",
        "x": 4,
        "y": 11,
        "answer": "I",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfaa",
        "x": 5,
        "y": 11,
        "answer": "G",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfa9",
        "x": 6,
        "y": 11,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfa8",
        "x": 7,
        "y": 11,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfa7",
        "x": 8,
        "y": 11,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfa6",
        "x": 9,
        "y": 11,
        "answer": "D",
        "question_num": 28,
        "question_vertical": "Körperspray (Kurzwort)",
        "question_horizontal": "Soße zum Eintunken"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfa5",
        "x": 10,
        "y": 11,
        "answer": "I",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfa4",
        "x": 11,
        "y": 11,
        "answer": "P",
        "question_num": 29,
        "question_vertical": "ein pharm. Beruf (Abk.)",
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb9",
        "x": 1,
        "y": 12,
        "answer": "A",
        "question_num": 30,
        "question_vertical": null,
        "question_horizontal": "Abk.: an der"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb8",
        "x": 2,
        "y": 12,
        "answer": "D",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfb7",
        "x": 3,
        "y": 12,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb6",
        "x": 4,
        "y": 12,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfb5",
        "x": 5,
        "y": 12,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb4",
        "x": 6,
        "y": 12,
        "answer": "A",
        "question_num": 31,
        "question_vertical": "Initialen der engl. Autorin Christie †",
        "question_horizontal": "Bescheinigung des Arztes"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb3",
        "x": 7,
        "y": 12,
        "answer": "T",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb2",
        "x": 8,
        "y": 12,
        "answer": "T",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb1",
        "x": 9,
        "y": 12,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb0",
        "x": 10,
        "y": 12,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfaf",
        "x": 11,
        "y": 12,
        "answer": "T",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfc4",
        "x": 1,
        "y": 13,
        "answer": "J",
        "question_num": 32,
        "question_vertical": null,
        "question_horizontal": "Frauenname"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfc3",
        "x": 2,
        "y": 13,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfc2",
        "x": 3,
        "y": 13,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfc1",
        "x": 4,
        "y": 13,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfc0",
        "x": 5,
        "y": 13,
        "answer": "I",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfbf",
        "x": 6,
        "y": 13,
        "answer": "C",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfbe",
        "x": 7,
        "y": 13,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfbd",
        "x": 8,
        "y": 13,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfbc",
        "x": 9,
        "y": 13,
        "answer": "O",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfbb",
        "x": 10,
        "y": 13,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfba",
        "x": 11,
        "y": 13,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ]
  ],
  "grid": [
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf40",
        "x": 1,
        "y": 1,
        "answer": "A",
        "question_num": 1,
        "question_vertical": "englisch, französisch: Alter",
        "question_horizontal": "eh. türk. Titel für Offiziere u. Beamte"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf3f",
        "x": 2,
        "y": 1,
        "answer": "G",
        "question_num": 2,
        "question_vertical": "Violinenspieler",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf3e",
        "x": 3,
        "y": 1,
        "answer": "A",
        "question_num": 3,
        "question_vertical": "US-Militärsender (Abk.)",
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf3d",
        "x": 4,
        "y": 1,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf3c",
        "x": 5,
        "y": 1,
        "answer": "E",
        "question_num": 4,
        "question_vertical": "deutsche Vorsilbe",
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf3b",
        "x": 6,
        "y": 1,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf3a",
        "x": 7,
        "y": 1,
        "answer": "R",
        "question_num": 5,
        "question_vertical": "Siebenschläfer",
        "question_horizontal": "Schauspieleraufgabe"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf39",
        "x": 8,
        "y": 1,
        "answer": "O",
        "question_num": 6,
        "question_vertical": "Schalterstellung (engl.)",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf38",
        "x": 9,
        "y": 1,
        "answer": "L",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf37",
        "x": 10,
        "y": 1,
        "answer": "L",
        "question_num": 7,
        "question_vertical": "kraftlos, lasch, matt",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf36",
        "x": 11,
        "y": 1,
        "answer": "E",
        "question_num": 8,
        "question_vertical": "australischer Laufvogel",
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf4b",
        "x": 1,
        "y": 2,
        "answer": "G",
        "question_num": 9,
        "question_vertical": "Violinenspieler",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf4a",
        "x": 2,
        "y": 2,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf49",
        "x": 3,
        "y": 2,
        "answer": "F",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf48",
        "x": 4,
        "y": 2,
        "answer": "A",
        "question_num": 10,
        "question_vertical": "griechische Vorsilbe: Stern",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf47",
        "x": 5,
        "y": 2,
        "answer": "N",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf46",
        "x": 6,
        "y": 2,
        "answer": "G",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf45",
        "x": 7,
        "y": 2,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf44",
        "x": 8,
        "y": 2,
        "answer": "N",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf43",
        "x": 9,
        "y": 2,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf42",
        "x": 10,
        "y": 2,
        "answer": "A",
        "question_num": 11,
        "question_vertical": null,
        "question_horizontal": "kurz für: an dem"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf41",
        "x": 11,
        "y": 2,
        "answer": "M",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf56",
        "x": 1,
        "y": 3,
        "answer": "E",
        "question_num": 12,
        "question_vertical": "US-Militärsender (Abk.)",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf55",
        "x": 2,
        "y": 3,
        "answer": "I",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf54",
        "x": 3,
        "y": 3,
        "answer": "N",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf53",
        "x": 4,
        "y": 3,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf52",
        "x": 5,
        "y": 3,
        "answer": "T",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf51",
        "x": 6,
        "y": 3,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf50",
        "x": 7,
        "y": 3,
        "answer": "L",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf4f",
        "x": 8,
        "y": 3,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf4e",
        "x": 9,
        "y": 3,
        "answer": "U",
        "question_num": 13,
        "question_vertical": "Papstname",
        "question_horizontal": "größte europäische Eule"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf4d",
        "x": 10,
        "y": 3,
        "answer": "H",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf4c",
        "x": 11,
        "y": 3,
        "answer": "U",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf61",
        "x": 1,
        "y": 4,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf60",
        "x": 2,
        "y": 4,
        "answer": "G",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf5f",
        "x": 3,
        "y": 4,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf5e",
        "x": 4,
        "y": 4,
        "answer": "T",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf5d",
        "x": 5,
        "y": 4,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf5c",
        "x": 6,
        "y": 4,
        "answer": "A",
        "question_num": 14,
        "question_vertical": "Brandrückstand",
        "question_horizontal": "Warnsignalruf"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf5b",
        "x": 7,
        "y": 4,
        "answer": "L",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf5a",
        "x": 8,
        "y": 4,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf59",
        "x": 9,
        "y": 4,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf58",
        "x": 10,
        "y": 4,
        "answer": "M",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf57",
        "x": 11,
        "y": 4,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf6c",
        "x": 1,
        "y": 5,
        "answer": "S",
        "question_num": 15,
        "question_vertical": "ein Brettspiel",
        "question_horizontal": "Teichblume"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf6b",
        "x": 2,
        "y": 5,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf6a",
        "x": 3,
        "y": 5,
        "answer": "E",
        "question_num": 16,
        "question_vertical": "biblischer Ort  im A.T. (2 Worte)",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf69",
        "x": 4,
        "y": 5,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf68",
        "x": 5,
        "y": 5,
        "answer": "O",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf67",
        "x": 6,
        "y": 5,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf66",
        "x": 7,
        "y": 5,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf65",
        "x": 8,
        "y": 5,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf64",
        "x": 9,
        "y": 5,
        "answer": "B",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf63",
        "x": 10,
        "y": 5,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf62",
        "x": 11,
        "y": 5,
        "answer": "A",
        "question_num": 17,
        "question_vertical": "Opferstein, -tisch",
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf77",
        "x": 1,
        "y": 6,
        "answer": "A",
        "question_num": 18,
        "question_vertical": null,
        "question_horizontal": "Fluss durch Florenz"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf76",
        "x": 2,
        "y": 6,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf75",
        "x": 3,
        "y": 6,
        "answer": "N",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf74",
        "x": 4,
        "y": 6,
        "answer": "O",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf73",
        "x": 5,
        "y": 6,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf72",
        "x": 6,
        "y": 6,
        "answer": "C",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf71",
        "x": 7,
        "y": 6,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf70",
        "x": 8,
        "y": 6,
        "answer": "B",
        "question_num": 19,
        "question_vertical": "Stadt an der Fulda",
        "question_horizontal": "Theatherstück von Brecht"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf6f",
        "x": 9,
        "y": 6,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf6e",
        "x": 10,
        "y": 6,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf6d",
        "x": 11,
        "y": 6,
        "answer": "L",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf82",
        "x": 1,
        "y": 7,
        "answer": "L",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf81",
        "x": 2,
        "y": 7,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf80",
        "x": 3,
        "y": 7,
        "answer": "D",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf7f",
        "x": 4,
        "y": 7,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf7e",
        "x": 5,
        "y": 7,
        "answer": "E",
        "question_num": 20,
        "question_vertical": "flüssiges Gewürz",
        "question_horizontal": "jemandem Achtung erweisen"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf7d",
        "x": 6,
        "y": 7,
        "answer": "H",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf7c",
        "x": 7,
        "y": 7,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf7b",
        "x": 8,
        "y": 7,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf7a",
        "x": 9,
        "y": 7,
        "answer": "N",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf79",
        "x": 10,
        "y": 7,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf78",
        "x": 11,
        "y": 7,
        "answer": "T",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf8d",
        "x": 1,
        "y": 8,
        "answer": "T",
        "question_num": 21,
        "question_vertical": null,
        "question_horizontal": "Stahlseil"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf8c",
        "x": 2,
        "y": 8,
        "answer": "R",
        "question_num": 22,
        "question_vertical": "Hautkrankheit bei Haustieren",
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf8b",
        "x": 3,
        "y": 8,
        "answer": "O",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf8a",
        "x": 4,
        "y": 8,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf89",
        "x": 5,
        "y": 8,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf88",
        "x": 6,
        "y": 8,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf87",
        "x": 7,
        "y": 8,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf86",
        "x": 8,
        "y": 8,
        "answer": "B",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf85",
        "x": 9,
        "y": 8,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf84",
        "x": 10,
        "y": 8,
        "answer": "N",
        "question_num": 23,
        "question_vertical": "Karibik-Insel",
        "question_horizontal": "chem. Zeichen: Natrium"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf83",
        "x": 11,
        "y": 8,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf98",
        "x": 1,
        "y": 9,
        "answer": "A",
        "question_num": 24,
        "question_vertical": null,
        "question_horizontal": "gehoben für: Adler"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf97",
        "x": 2,
        "y": 9,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf96",
        "x": 3,
        "y": 9,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf95",
        "x": 4,
        "y": 9,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf94",
        "x": 5,
        "y": 9,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf93",
        "x": 6,
        "y": 9,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf92",
        "x": 7,
        "y": 9,
        "answer": "T",
        "question_num": 25,
        "question_vertical": "griechischer Buchstabe",
        "question_horizontal": "Domstadt an der Mosel"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf91",
        "x": 8,
        "y": 9,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf90",
        "x": 9,
        "y": 9,
        "answer": "I",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf8f",
        "x": 10,
        "y": 9,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf8e",
        "x": 11,
        "y": 9,
        "answer": "R",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfa3",
        "x": 1,
        "y": 10,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfa2",
        "x": 2,
        "y": 10,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfa1",
        "x": 3,
        "y": 10,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfa0",
        "x": 4,
        "y": 10,
        "answer": "M",
        "question_num": 26,
        "question_vertical": "übel, schlecht",
        "question_horizontal": "Männerkurzname"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf9f",
        "x": 5,
        "y": 10,
        "answer": "I",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf9e",
        "x": 6,
        "y": 10,
        "answer": "C",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf9d",
        "x": 7,
        "y": 10,
        "answer": "H",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf9c",
        "x": 8,
        "y": 10,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf9b",
        "x": 9,
        "y": 10,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cf9a",
        "x": 10,
        "y": 10,
        "answer": "V",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cf99",
        "x": 11,
        "y": 10,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfae",
        "x": 1,
        "y": 11,
        "answer": "R",
        "question_num": 27,
        "question_vertical": "indischer Dichter † 1913",
        "question_horizontal": "lautlos"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfad",
        "x": 2,
        "y": 11,
        "answer": "U",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfac",
        "x": 3,
        "y": 11,
        "answer": "H",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfab",
        "x": 4,
        "y": 11,
        "answer": "I",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfaa",
        "x": 5,
        "y": 11,
        "answer": "G",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfa9",
        "x": 6,
        "y": 11,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfa8",
        "x": 7,
        "y": 11,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfa7",
        "x": 8,
        "y": 11,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfa6",
        "x": 9,
        "y": 11,
        "answer": "D",
        "question_num": 28,
        "question_vertical": "Körperspray (Kurzwort)",
        "question_horizontal": "Soße zum Eintunken"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfa5",
        "x": 10,
        "y": 11,
        "answer": "I",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfa4",
        "x": 11,
        "y": 11,
        "answer": "P",
        "question_num": 29,
        "question_vertical": "ein pharm. Beruf (Abk.)",
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb9",
        "x": 1,
        "y": 12,
        "answer": "A",
        "question_num": 30,
        "question_vertical": null,
        "question_horizontal": "Abk.: an der"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb8",
        "x": 2,
        "y": 12,
        "answer": "D",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfb7",
        "x": 3,
        "y": 12,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb6",
        "x": 4,
        "y": 12,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfb5",
        "x": 5,
        "y": 12,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb4",
        "x": 6,
        "y": 12,
        "answer": "A",
        "question_num": 31,
        "question_vertical": "Initialen der engl. Autorin Christie †",
        "question_horizontal": "Bescheinigung des Arztes"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb3",
        "x": 7,
        "y": 12,
        "answer": "T",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb2",
        "x": 8,
        "y": 12,
        "answer": "T",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb1",
        "x": 9,
        "y": 12,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfb0",
        "x": 10,
        "y": 12,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfaf",
        "x": 11,
        "y": 12,
        "answer": "T",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ],
    [
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfc4",
        "x": 1,
        "y": 13,
        "answer": "J",
        "question_num": 32,
        "question_vertical": null,
        "question_horizontal": "Frauenname"
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfc3",
        "x": 2,
        "y": 13,
        "answer": "E",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfc2",
        "x": 3,
        "y": 13,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfc1",
        "x": 4,
        "y": 13,
        "answer": "S",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfc0",
        "x": 5,
        "y": 13,
        "answer": "I",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfbf",
        "x": 6,
        "y": 13,
        "answer": "C",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfbe",
        "x": 7,
        "y": 13,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfbd",
        "x": 8,
        "y": 13,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfbc",
        "x": 9,
        "y": 13,
        "answer": "O",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": true,
        "_id": "5dec2f00de95855cf911cfbb",
        "x": 10,
        "y": 13,
        "answer": null,
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      },
      {
        "block": false,
        "_id": "5dec2f00de95855cf911cfba",
        "x": 11,
        "y": 13,
        "answer": "A",
        "question_num": null,
        "question_vertical": null,
        "question_horizontal": null
      }
    ]
  ],
  "identifier": "4956d8769329e6d572d57fa2e761dbe7",
  "cat": "noneyet",
  "name": "nonameyet",
  "daily": true,
  "day": "2021-01-29T00:00:00.000Z",
  "open": true,
  "points": 100,
  "packageName": "Package undefined",
  "dailymini": false,
  "author": "Witte",
  "size": 13,
  "totalGrid": 113,
  "singlePoint": 0.8849557522123894,
  "premium": true,
  "status": 2,
  "uuid": "PMA-499",
  "__v": 0
}

export const preloadPuzzle = (category: 'MAXI' | 'MINI') => {
  if(category === 'MAXI') {
    return puzzle
  } else {
    return {...puzzle, daily: false, dailymini: true }
  }
} 