<div>
    <h3 class="segment-select">4. Complete Notification</h3>
    <form [formGroup]="notificationForm" style="width: 350px;">
        <div class="form-well-header">
        </div>
        <div class="form-group lead emoji-picker-container">
            <!-- <label for="title">TITLE</label> -->
            <input class="form-control" data-emojiable="true" placeholder="Title" type="text" formControlName="title" id="title">
        </div>
        <div class="form-group lead emoji-picker-container">
            <!-- <label for="subtitle">SUBTITLE</label> -->
            <input class="form-control" data-emojiable="true" placeholder="Subtitle" type="text" formControlName="subtitle" id="subtitle">
        </div>
        <!-- <div class="form-group">
            <label for="subtitle">MESSAGE</label>
            <textarea class="form-control" placeholder="Message" formControlName="message" id="" id="message" cols="30"
                rows="3"></textarea>
        </div> -->     
        <p class="lead emoji-picker-container">
            <textarea class="form-control textarea-control" formControlName="message" rows="3" placeholder="Textarea with emoji Unicode input"
                data-emojiable="true" data-emoji-input="unicode"></textarea>
        </p>
        <div class="form-group upload-container">
            <label for="upload">IMAGE</label>
            <input class="upload" placeholder="Upload Image" formControlName="image" type="file" id="upload" id="">
        </div>
        <div class="form-group">
            <label for="upload">LAUNCH URL</label>
            <input class="form-control" placeholder="Launch URL" formControlName="url" type="text" id="url" id="">
        </div>
     
    </form>
</div>
<!-- <div class="container">
    <div class="row justify-content-center">
        <div class="col-10">
            <div class="text-left">
                <p class="lead emoji-picker-container">
                    <input type="email" class="form-control" placeholder="Input field" data-emojiable="true">
                </p>
                <p class="lead emoji-picker-container">
                    <input type="email" class="form-control" placeholder="Input with max length of 10"
                        data-emojiable="true" maxlength="10">
                </p>
                <p class="lead emoji-picker-container">
                    <textarea class="form-control textarea-control" rows="3"
                        placeholder="Textarea with emoji image input" data-emojiable="true"></textarea>
                </p>
                <p class="lead emoji-picker-container">
                    <textarea class="form-control textarea-control" rows="3"
                        placeholder="Textarea with emoji Unicode input" data-emojiable="true"
                        data-emoji-input="unicode"></textarea>
                </p>
            </div>
        </div>
    </div> -->