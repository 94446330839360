import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DailyPackagesService } from 'src/app/daily-packages.service ';
import { Puzzle } from 'src/app/dashboard/dashboard/puzzle-view/table-puzzle/table-puzzle.component';
import { PuzzlesConfigService } from 'src/app/puzzles-config.service';
import { PackageSettingModalComponent } from './package-setting-modal/package-setting-modal.component';

import { testData } from './testDataDailyPackages'

export interface DailyPackages {
  canceled?: boolean,
  publishDate: number
  lite: PuzzlesConfiguration
  registered: PuzzlesConfiguration
  premium: PuzzlesConfiguration
  name?: 'config' | 'premium'
}

export interface PuzzlesConfiguration {
  mini: puzzleData[]
  maxi: puzzleData[]
  total?: number
}

export type puzzleData = Pick<Puzzle, "_id" | "uuid">;

@Component({
  selector: 'app-publishing-calendar',
  templateUrl: './publishing-calendar.component.html',
  styleUrls: ['./publishing-calendar.component.scss']
})
export class PublishingCalendarComponent {

  date = null;
  dayilyPackages: DailyPackages[] = [];
  isLoaded = false;

  listDataMap = {
    eight: [
      { type: 'warning', content: '' },
    ],
    ten: [
      { type: 'success', content: '' },
    ],
    eleven: [
      { type: 'error', content: '' },
    ]
  };

  openPackageEditingModal(chosenDate: Date): void {


    let dateAsID = this.createPseudoIdFromDate(chosenDate)

    const dailyPackage = this.dayilyPackages.find(p => {

      const ID = this.createPseudoIdFromDate(new Date(p.publishDate))
      return dateAsID === ID
    });

    if (dailyPackage) {
      console.log(dailyPackage, new Date(dailyPackage.publishDate))
     const modal =  this.modalService.create({
        nzTitle: 'Package Setting',
        nzContent: PackageSettingModalComponent
      })

      if(modal.componentInstance) {
        modal['componentInstance'].dailyPackage = dailyPackage
      }
    
    }
  }


  createPseudoIdFromDate(date: Date): string {
    return `${date.getFullYear()}${date.getMonth()}.${date.getDate()}`
  }

  dailyPackagesObject: any = {}

  constructor(private modalService: NzModalService,
    private dailyPackagesService: DailyPackagesService,
    private puzzlesPublishConfig: PuzzlesConfigService) {
    this.loadConfig()
  }

  async loadConfig() {

    const config = await this.puzzlesPublishConfig.getConfigPromise()
    
    
    const dailyPackages = await this.dailyPackagesService.getConfigPromise()
    this.dayilyPackages = dailyPackages 
    // console.log(config,dailyPackages, 'configgggggg')

    this.dailyPackagesObject = dailyPackages
      // .reduce<DailyPackages>((prev, curr) => {
      .filter( p => p.name !== "premium")
      .reduce((prev, curr) => {

        console.log(curr, 'curr');
        let dateAsID = this.createPseudoIdFromDate(new Date(curr.publishDate))

        let distinguishFromDefaultValues = false;

        if (curr.canceled) {
          return {
            ...prev,
            [dateAsID]: { canceled: true }
          }
        }

        //lite
        // console.log(curr.lite.maxi.length, config.lite.maxi);
        
        if (curr.lite.maxi.length !== config.lite.maxi) {
          distinguishFromDefaultValues = true;
        }
        
        // console.log(curr.lite.mini.length, config.lite.mini);
        if (curr.lite.mini.length !== config.lite.mini) {
          distinguishFromDefaultValues = true;
        }
        //registered
        // console.log(curr.lite.mini.length, config.lite.mini);
        if (curr.registered.maxi.length !== config.registered.maxi) {
          distinguishFromDefaultValues = true;
        }

        if (curr.registered.mini.length !== config.registered.mini) {
          distinguishFromDefaultValues = true;
        }
        //premium
        if (curr.premium.maxi.length !== config.premium.maxi) {
          distinguishFromDefaultValues = true;
        }

        if (curr.premium.mini.length !== config.premium.mini) {
          distinguishFromDefaultValues = true;
        }

        return { ...prev, [dateAsID]: { distinguishFromDefaultValues } }
      }, {})




    this.isLoaded = true;

  }

  setCellColor(date: Date = new Date()) {
    let dateAsID = this.createPseudoIdFromDate(date)
    if (this.dailyPackagesObject[dateAsID]) {
      const obj = this.dailyPackagesObject[dateAsID];

      if (obj.canceled && obj.canceled === true) {
        return 'cell-red'
      } else if (obj.distinguishFromDefaultValues && obj.distinguishFromDefaultValues === true) {
        return 'cell-yellow'
      } else {
        return 'cell-green'
      }

    }
    return '';
  }

  getMonthData(date: Date): number | null {
    if (date.getMonth() === 8) {
      return 1394;
    }
    return null;
  }

}
