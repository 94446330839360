import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { PaginateData } from './users-table/users-table.component';
import { searchCategory } from './users-table/users-table.component'

export interface UserStatistics {
  playedPuzzlesTotal: number,
  totalPointsLastMonth: number,
  totalPoints: number,
  miniPuzzlesTotal: number,
  maxiPuzzlesTotal: number
}

@Injectable()
export class UsersService {

  backendUrl = environment.backendUrl;

  constructor(private http: HttpClient) { }

  getAllUsers(paginateData: PaginateData): Observable<any> {
    return this.http.get(`${this.backendUrl}/api/usersView/list`, {
      params: {
        page: `${paginateData.page}`,
        pageSize: `${paginateData.pageSize}`
      }
    })
  }

  searchUsers(filters: any): Observable<any> {
    return this.http.post(`${this.backendUrl}/api/usersView/search`, filters)
  }

  getUserInfo(_id: string): Observable<any> {
    return this.http.get(`${this.backendUrl}/api/usersView/userInfo`, {
      params: {
        userId: _id,
      }
    })
  }

  getUserBestListe(): Observable<any> {
    return this.http.get(`${this.backendUrl}/api/usersView/userInfoRankingStatistics`)
  }

  deleteUser(_id: string): Observable<any> {
    return this.http.delete(`${this.backendUrl} / api / usersView / deleteUser / ${_id}`)
  }

}
