import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CreateNotificationService } from '../create-notification.service';
import * as $ from "jquery";

declare var EmojiPicker;


@Component({
  selector: 'app-notification-form',
  templateUrl: './notification-form.component.html',
  styleUrls: ['./notification-form.component.scss']
})
export class NotificationFormComponent implements OnInit, AfterViewInit {

  notificationForm = new FormGroup({
    title: new FormControl(''),
    subtitle: new FormControl(''),
    message: new FormControl(''),
    image: new FormControl(''),
    url: new FormControl(''),
  });


  constructor(
    private notificationS: CreateNotificationService) {

    this.notificationForm.valueChanges
      .subscribe(value => {
        console.log(value, 'value');
        this.notificationS.refreshNotificationData$.next(value)
      })
  }
  ngAfterViewInit(): void {
    $(function () {
      // Initializes and creates emoji set from sprite sheet

      if ((window as any).emojiPicker) {
          (window as any).emojiPicker = new EmojiPicker({
          emojiable_selector: '[data-emojiable=true]',
          assetsPath: './lib/img/',
          popupButtonClasses: 'fa fa-smile-o'
        });
        // Finds all elements with `emojiable_selector` and converts them to rich emoji input fields
        // You may want to delay this step if you have dynamically created input fields that appear later in the loading process
        // It can be called as many times as necessary; previously converted input fields will not be converted again
        (window as any).emojiPicker.discover();
      }

    });
  }

  ngOnInit(): void {
  }

}
