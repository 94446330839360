<nz-layout class="app-layout">
    <nz-sider class="menu-sidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
        [nzTrigger]="null">
        <div class="sidebar-logo">
            <a href="https://ng.ant.design/" target="_blank">
                <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo">
                <h1>KREUZWORT TOTAL</h1>
            </a>
        </div>
        <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
            <li nz-submenu nzOpen nzTitle="Dashboard" nzIcon="dashboard">
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/app/publishingList">Publishing List</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/app/premiumBucket">Premium Bucket</a>
                    </li>
                </ul>
            </li>
            <ul>
                <li nz-menu-item nzMatchRouter>
                    <a routerLink="/app/usersManagment">User-Datenbank</a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a routerLink="/app/statistics">Statistic</a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a routerLink="/app/pushNotifications">Push-Nachrichten</a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a routerLink="/app/createNotification">Create-Nachrichten</a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a routerLink="/app/binView">Bin</a>
                </li>
                
            </ul>
        </ul>
    </nz-sider>
    <nz-layout>
        <nz-header>
            <div class="app-header">
                <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
                    <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
                </span>
                <button nz-button nzType="primary" (click)="signOut()" nzDanger>Logout</button>
            </div>
        </nz-header>
        <nz-content>
            <div class="inner-content">
                <router-outlet></router-outlet>
            </div>
        </nz-content>
    </nz-layout>
</nz-layout>