import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremiumViewComponent } from './premium-view/premium-view.component';
import { FormsModule } from '@angular/forms';
import { PremiumPuzzleService } from './premium-puzzle.service';
import { PuzzlesPremiumListComponent } from './puzzles-premium-list/puzzles-premium-list.component';
import { SharedPipesModule } from 'src/app/shared/shared-pipes/shared-pipes.module';
import { CancelPuzzlePublishingConfirmModalComponent } from './premium-view/cancel-puzzle-publishing-confirm-modal/cancel-puzzle-publishing-confirm-modal.component';
import { ChangePublishDateModalComponent } from './premium-view/change-publish-date-modal/change-publish-date-modal.component';
import { NgbModule, NgbNav } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    PremiumViewComponent,
    PuzzlesPremiumListComponent,
    CancelPuzzlePublishingConfirmModalComponent,
    ChangePublishDateModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    SharedPipesModule
  ],
  providers: [PremiumPuzzleService],
  exports: [PremiumViewComponent]
})

export class PremiumViewModule { }
