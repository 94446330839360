import { Component, Input } from '@angular/core';
import { CreateNotificationService } from '../../create-notification.service';

export type os = 'ios' | 'android'

interface NotificationData {
  title: string,
  subtitle: string,
  message: string,
  image: string,
  url: string,
}

@Component({
  selector: 'app-phone-apple',
  templateUrl: './phone-apple.component.html',
  styleUrls: ['./phone-apple.component.scss']
})
export class PhoneAppleComponent {

  public notificationData: NotificationData = {
    title: '',
    subtitle: '',
    message: '',
    image: '',
    url: ''
  }

  @Input() system: os = 'ios'

  constructor(private notificationS: CreateNotificationService) {
    this.notificationS.refreshNotificationData$.subscribe(v => {
      this.notificationData = v as NotificationData
      console.log('dsad', v);
      
    })
  }

  ngOnInit(): void {
  }

}
