import { Component } from '@angular/core';
import { DailyPackagesService } from 'src/app/daily-packages.service ';
import { DailyPackageConfig } from '../../puzzles-publishing/publishing-calendar/package-setting-modal/packges-puzzles-config-modal/packges-puzzles-config-modal.component';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent {

  source: any;
  isLoading=  true;

  constructor(private service: DailyPackagesService) {
    this.loadPackage()
  }

  loadPackage() {
    this.service.getPremiumPackage()
      .subscribe(package1 => {
        this.source = {
          dailyPackage: package1,
          profilType: 'premium',
          puzzlesCategory: 'maxi'
        }
        this.isLoading = false;
        console.log(this.source, 'source')
      })
  }

  changeCategory($event: 'maxi' | 'mini') {
    this.source = {
      dailyPackage: this.source.dailyPackage,
      profilType: 'premium',
      puzzlesCategory: 'mini'
    }
  }
}



