import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PuzzlesService } from './puzzles.service';
import { Puzzle } from './table-puzzle/table-puzzle.component';

export interface filter {
  daily?: boolean,
  dailymini?: boolean,
  open?: boolean | undefined,
  premium?: boolean | undefined,
  status?: string | number | any,
  category: string
}

@Component({
  selector: 'app-puzzle-view',
  template: `
    <app-edit-puzzle-form [puzzlesCategory]="puzzlesCategory" (onEditedPuzzle)="onEditedPuzzle($event)" class="mx-auto" [puzzlesArr]="puzzlesArr"></app-edit-puzzle-form>
    <app-table-puzzle [editedPuzzle]="editedPuzzle" (onPuzzlesReceived)="onPuzzlesReceived($event)"
    [category]="filter.category" 
      class="col-12">
    </app-table-puzzle>
  `,
})
export class PuzzleViewComponent implements OnChanges {

  @Input() filter: filter = { category: 'maxi' }


  puzzlesCategory: 'MAXI' | 'MINI' = 'MAXI'

  load = false;

  puzzlesArr: Puzzle[] = []

  editedPuzzle: any = {}

  constructor(
    public puzzleService: PuzzlesService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes, 'changes');

    this.puzzlesCategory = changes.filter.currentValue.category.toUpperCase()
  }

  onPuzzlesReceived(puzzles: Puzzle[]) {

    const puzzlesIds = puzzles.map(p => p._id)

    console.log(puzzlesIds, 'ids')


    this.puzzleService.getManyPuzzle(puzzlesIds)
      .subscribe(p => {
        this.load = true;
        this.puzzlesArr = p;
        console.log(this.puzzlesArr[0], 'initial');
        
      })

    //  puzzleModal.componentInstance.editPuzzle = editPuzzle;
    //  puzzleModal.componentInstance.editPuzzle.points = editPuzzle.totalGrid;

    //  puzzleModal.componentInstance.puzzle = puzzle


  }

  onEditedPuzzle(puzzle: Puzzle) {    
    this.editedPuzzle = puzzle;
  }

}
