import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { InterprecetorService } from 'src/app/interprecetor.service';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from '../welcome/welcome.component';
import { IconsProviderModule } from 'src/app/icons-provider.module';
import { PagesGuard } from './layout.guard';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { PagesComponent } from './layout.component';
import { PageLayoutComponent as c } from '../puzzles-publishing/page-layout/page-layout.component';
import { PageLayoutComponent as d } from '../premium-bucket/page-layout/page-layout.component';
import { PuzzlesPublishingModule } from '../puzzles-publishing/puzzles-publishing.module';
import { PremiumBucketModule } from '../premium-bucket/premium-bucket.module';
import { DailyPackageConfigModule } from '../shared/daily-package-config/daily-package-config/daily-package-config.module';
import { DashboardModule } from 'src/app/dashboard/dashboard.module';
import { UsersLayoutComponent } from 'src/app/dashboard/dashboard/users-view/users-layout/users-layout.component';
import { StatisticsViewComponent } from 'src/app/dashboard/dashboard/statistics-view/statistics-view/statistics-view.component';
import { NotificationsViewComponent } from 'src/app/dashboard/dashboard/notifications-view/notifications-view/notifications-view.component';
import { CreateNotificationViewComponent } from 'src/app/dashboard/create-notification-view/create-notification-view/create-notification-view.component';
import { BinPuzzleViewComponent } from 'src/app/dashboard/dashboard/bin-puzzle-view/bin-puzzle-view/bin-puzzle-view.component';

const routes: Routes = [
  {
    path: 'app', component: PagesComponent, children: [
      { path: 'pages', pathMatch: 'full', redirectTo: '/welcome' },
      { path: 'publishingList', component: c },
      { path: 'premiumBucket', component: d },
      { path: 'usersManagment', component: UsersLayoutComponent },
      { path: 'statistics', component: StatisticsViewComponent },
      { path: 'pushNotifications', component: NotificationsViewComponent },
      { path: 'createNotification', component: CreateNotificationViewComponent },
      { path: 'binView', component: BinPuzzleViewComponent },
    ],
  }
];

registerLocaleData(en);

@NgModule({
  declarations: [
    PagesComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    PuzzlesPublishingModule,
    PremiumBucketModule,
    DailyPackageConfigModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule,
    NzLayoutModule,
    IconsProviderModule,
    NzMenuModule,
    NzButtonModule,
    DashboardModule
  ],

  providers:
    [{ provide: NZ_I18N, useValue: en_US }],

  bootstrap: [PagesComponent]
})
export class LayoutModule { }
