import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Puzzle } from './table-puzzle/table-puzzle.component';
import { map } from 'rxjs/operators';
import { PaginateData } from '../users-view/users-table/users-table.component';

@Injectable({
  providedIn: 'root'
})
export class PuzzlesService {

  backendUrl = environment.backendUrl;

  constructor(private http: HttpClient) { }

  getPuzzles(cat: string, paginateData: PaginateData): Observable<any> {
    return this.http.get(`${this.backendUrl}/api/usersView/puzzle`, {
      params: {
        page: `${paginateData.page}`,
        pageSize: `${paginateData.pageSize}`,
        category: cat
      }
    }).pipe(
      map((response: any) => {
        response = response.list.filter(
          (puzzle: Puzzle) => {
            return puzzle.premium == true || puzzle.daily == true || puzzle.dailymini == true || puzzle.open == true
          }) as Puzzle[]
        return response
      }))
  }

  getSinglePuzzle(id: string): Observable<any> {
    return this.http.get(`${this.backendUrl}/api/puzzle/${id}`);
  }

  getManyPuzzle(puzzlesIds: string[]): Observable<any> {
    return this.http.post(`${this.backendUrl}/api/puzzleView/puzzlesIds`, puzzlesIds);
  }


  updatePuzzle(id: string, puzzle: Puzzle): Observable<any> {
    return this.http.put(`${this.backendUrl}/api/puzzle/${id}`, puzzle);
  }

  updateQuestion(id: string, question: any): Observable<any> {
    return this.http.put(`${this.backendUrl}/api/puzzle/question/${id}`, question);
  }

  public refreshPuzzleTable$ = new BehaviorSubject(null as any);

}

