import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsViewComponent } from './notifications-view/notifications-view.component';
import { NotficationsTableComponent } from './notfications-table/notfications-table.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  declarations: [NotificationsViewComponent, NotficationsTableComponent],
  imports: [
    CommonModule,
    NzSpinModule
  ],
  exports: [NotificationsViewComponent]
})
export class NotificationsViewModule { }
