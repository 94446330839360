import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Puzzle } from '../../../puzzle-view/table-puzzle/table-puzzle.component';

@Component({
  template: `
   <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Cancellation of Publishing</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>Are you sure you want to cancel publishing puzzle  <span class="text-primary">{{puzzle.uuid | puzzlesCategory}} {{puzzle.uuid}}</span> ?</strong></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close('Ok click')">Ok</button>
  </div>
    `,
})
export class CancelPuzzlePublishingConfirmModalComponent  {

  @Input() puzzle: Puzzle | any = {};

  constructor(public modal: NgbActiveModal) { }
  
}


