import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatisticsViewComponent } from './statistics-view/statistics-view.component';
import { UsersPieChartComponent } from './users-pie-chart/users-pie-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UsersLineChartComponent } from './users-line-chart/users-line-chart.component';
import { OnlineUsersLineChartComponent } from './online-users-line-chart/online-users-line-chart.component';

@NgModule({
  declarations: [StatisticsViewComponent, UsersPieChartComponent, UsersLineChartComponent, OnlineUsersLineChartComponent],
  imports: [
    CommonModule,
    NgxChartsModule
  ],
  exports: [StatisticsViewComponent]
})
export class StatisticsViewModule { }
