import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PuzzlesService } from '../puzzles.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaginateData } from '../../users-view/users-table/users-table.component';
import { EditPuzzleModalComponent } from '../puzzle-edit/edit-puzzle-modal/edit-puzzle-modal.component';

export interface Puzzle {
  uuid: string
  day: string
  open: boolean
  totalGrid: number
  points: number
  _id: string
  daily: boolean,
  dailymini: boolean,
  premium: boolean | undefined,
  status: number
  complainComment?: string
  grid: any
  grid2: any
  date?: Date
}

@Component({
  selector: 'app-table-puzzle',
  templateUrl: './table-puzzle.component.html',
  styles: [
    `
       button {
        white-space: nowrap;
       }
       
       .btn-block {
        background-color: #EFEFEF
       }

       .bin {
         background-color: #ebb9b9;
       }

       .undone {
        background-color: blue;
       }

       .scroll {
        display: block;
        overflow-y: scroll;
        overflow-x: scroll;
        max-height: 900px;
        scroll-behavior:auto;
      }
    `
  ]
})
export class TablePuzzleComponent {

  puzzles: Puzzle[] = []

  loading = true;

  categoryNow = "";


  @Input() set category(category: string) {

    this.paginate = {
      page: 1,
      pageSize: 30
    }
    this.loading = true;

    this.puzzles = []
    this.categoryNow = category;

    this.getPuzzles(category)
  }

  @Input() set editedPuzzle(editedPuzzle: Puzzle) {
    console.log(editedPuzzle, 'editedPuzzle puzzle')

    this.puzzles = this.puzzles.map(p => {
      if (p._id === editedPuzzle._id) {
        delete editedPuzzle.grid
        delete editedPuzzle.grid2
        return editedPuzzle
      } else {
        return p
      }
    })
  }

  @Output() onPuzzlesReceived = new EventEmitter<Puzzle[]>()

  @Output() onChosenPremiumPuzzle = new EventEmitter<Puzzle>()

  constructor(private puzzleService: PuzzlesService, private modalService: NgbModal) { }

  paginate: PaginateData = {
    page: 1,
    pageSize: 20
  }

  onScroll(event: any) {
    const scrolledBottom = event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight
    if (scrolledBottom) {
      this.paginate.page++;
      this.getPuzzles(this.categoryNow)
    }
  }

  getPuzzles(category: any) {
    this.puzzleService
      .getPuzzles(category, this.paginate)
      .subscribe(el => {
        this.puzzles = [...this.puzzles, ...el]
        this.loading = false;
        console.log(el, 'el')
        this.onPuzzlesReceived.emit(el)
      })
  }

  openPuzzle(editPuzzle: Puzzle) {
    this.puzzleService.getSinglePuzzle(editPuzzle._id)
      .subscribe(
        (puzzle) => {
          const puzzleModal = this.modalService.open(EditPuzzleModalComponent, {
            size: 'xl'
          })
          puzzleModal.componentInstance.editPuzzle = editPuzzle;
          puzzleModal.componentInstance.editPuzzle.points = editPuzzle.totalGrid;

          puzzleModal.componentInstance.puzzle = puzzle
        })

  }
  pushPuzzle(puzzle: Puzzle) {
    this.onChosenPremiumPuzzle.emit(puzzle)
  }

}
