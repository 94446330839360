import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Puzzle } from '../../puzzle-view/table-puzzle/table-puzzle.component';
import { BinPuzzleService } from '../bin-puzzle.service';

@Component({
  selector: 'app-bin-puzzle-view',
  template: `
  <div class="container-fluid">
      <div class="row">
        <div class="col-8">
          <app-bin-puzzle-table (onChosenPuzzles)="puzzlesIds = $event"></app-bin-puzzle-table>
        </div>
        <div class="col-4">
          <app-puzzles-complains-comments [puzzlesIds]="puzzlesIds"></app-puzzles-complains-comments>
        </div>
      </div>

  </div>
  `
})
export class BinPuzzleViewComponent {


  puzzlesIds: any = []


  constructor(public puzzleService: BinPuzzleService) { }

}
