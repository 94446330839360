import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PuzzlesConfigService, PuzzlesPublishingConfig } from 'src/app/puzzles-config.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/auth/auth.service';


export type profileType = 'Lite' | 'Registered' | 'Premium'

export interface AccountDefaultValues {
  type: profileType
  maxi: number
  mini: number
  total: number
}

@Component({
  selector: 'app-default-values',
  templateUrl: './default-values-settings.component.html',
  styleUrls: ['./default-values-settings.component.scss']
})
export class DefaultValuesSettingsComponent {

  listOfAccountsDefaultValues: AccountDefaultValues[] = [
    {
      type: 'Lite',
      maxi: 25,
      mini: 25,
      total: 50
    },
    {
      type: 'Registered',
      maxi: 50,
      mini: 50,
      total: 100
    },
    {
      type: 'Premium',
      maxi: 50,
      mini: 50,
      total: 99
    }
  ];

  constructor(
    private router: Router,
    private puzzlesPublishingConfig: PuzzlesConfigService,
    private auth: AuthService,
    private nzMessageService: NzMessageService
  ) {
    this.loadConfig()
  }

  loadConfig() {
    this.puzzlesPublishingConfig.getConfig()
      .subscribe(config => {

        this.listOfAccountsDefaultValues[0].maxi = config.lite.maxi
        this.listOfAccountsDefaultValues[0].mini = config.lite.mini

        this.listOfAccountsDefaultValues[1].maxi = config.registered.maxi
        this.listOfAccountsDefaultValues[1].mini = config.registered.mini

        this.listOfAccountsDefaultValues[2].maxi = config.premium.maxi
        this.listOfAccountsDefaultValues[2].mini = config.premium.mini
        console.log(config, 'err')
      })
  }

  saveConfig() {
    const updatedPuzzlesConfig: PuzzlesPublishingConfig = {
      lite: {
        maxi: this.listOfAccountsDefaultValues[0].maxi,
        mini: this.listOfAccountsDefaultValues[0].mini
      },
      registered: {
        maxi: this.listOfAccountsDefaultValues[1].maxi,
        mini: this.listOfAccountsDefaultValues[1].mini
      },
      premium: {
        maxi: this.listOfAccountsDefaultValues[2].maxi,
        mini: this.listOfAccountsDefaultValues[2].mini
      },
      name: "config"
    }

    this.nzMessageService.create('info', 'This action will reload the application')
    this.puzzlesPublishingConfig.updateConfig(updatedPuzzlesConfig)
      .subscribe(() => {
        this.auth.logout()
        this.router.navigate(['/'])
      })
  }

}
