import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BinPuzzleViewComponent } from './bin-puzzle-view/bin-puzzle-view.component';
import { BinPuzzleTableComponent } from './bin-puzzle-table/bin-puzzle-table.component';
import { PuzzlesComplainsCommentsComponent } from './puzzles-complains-comments/puzzles-complains-comments.component';
import { BinPuzzleService } from './bin-puzzle.service';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [
    BinPuzzleViewComponent,
    BinPuzzleTableComponent,
    PuzzlesComplainsCommentsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule
  ],
  providers: [BinPuzzleService],
  exports: [BinPuzzleViewComponent]
})


export class BinPuzzleViewModule { }
