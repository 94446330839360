import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { UserDeleteModalComponent } from '../user-delete-modal/user-delete-modal.component';
import { User } from '../users-layout/users-layout.component';
import { UsersService } from '../users.service';

interface Country {
  name: string;
  flag: string;
  area: number;
  population: number;
}

const COUNTRIES: Country[] = [
  {
    name: 'Russia',
    flag: 'f/f3/Flag_of_Russia.svg',
    area: 17075200,
    population: 146989754
  },
  {
    name: 'Canada',
    flag: 'c/cf/Flag_of_Canada.svg',
    area: 9976140,
    population: 36624199
  },
  {
    name: 'United States',
    flag: 'a/a4/Flag_of_the_United_States.svg',
    area: 9629091,
    population: 324459463
  },
  {
    name: 'China',
    flag: 'f/fa/Flag_of_the_People%27s_Republic_of_China.svg',
    area: 9596960,
    population: 1409517397
  }
];

function search(text: string, pipe: PipeTransform): Country[] {
  return COUNTRIES.filter(country => {
    const term = text.toLowerCase();
    return country.name.toLowerCase().includes(term)
      || pipe.transform(country.area).includes(term)
      || pipe.transform(country.population).includes(term);
  });
}

export type searchCategory = 'displayName' | 'email' | 'all'

export interface PaginateData {
  page: number
  pageSize: number
}

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent {

  searchEmail = ''
  searchName = ''

  paginate: PaginateData = {
    page: 1,
    pageSize: 20
  }
  loading = true;

  @Input() users$: User[] = []

  @Output() nextUser = new EventEmitter()

  filter = new FormControl('');

  onScroll(event: any) {

    const scrolledBottom = event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight

    if (scrolledBottom) {
      this.paginate.page++;
      this.getUsers()
    }
  }

  constructor(pipe: DecimalPipe, private modalSerivce: NgbModal,
    private usersService: UsersService, private toastr: ToastrService) {
    this.getUsers()
  }

  getUsers() {
    this.usersService
      .getAllUsers(this.paginate)
      .pipe(
        map(res => (res.list as User[])),
        map(users => {
          return users.map(user => {
            const timestamp = user._id.toString().substring(0, 8)
            return {
              ...user,
              date: new Date(parseInt(timestamp, 16) * 1000),
            }
          })
        })
      )
      .subscribe(el => {
        this.loading = false;
        this.users$ = [...this.users$, ...el]
      })
  }


  search() {

    let filter = {
      'email': this.searchEmail,
      'displayName': this.searchName
    }


    if (this.searchName.length < 2 && this.searchEmail.length < 2) {
     return this.toastr.error('Provide at least one filter')
    }

    if (this.searchName.length > 2 && this.searchEmail.length < 2) {
      filter = { 'displayName': this.searchName } as any;
    }

    if (this.searchName.length < 2 && this.searchEmail.length > 2) {
      filter = { 'email': this.searchEmail } as any;
    }



    this.usersService
      .searchUsers(filter)
      .pipe(map(res => (res.list as User[])),
        map(users => {
          return users.map(user => {
            const timestamp = user._id.toString().substring(0, 8)
            return {
              ...user,
              date: new Date(parseInt(timestamp, 16) * 1000),
            }
          })
        })
      )
      .subscribe(searchedUsers => {
        this.users$ = searchedUsers
      })
  }

  reset() {
    this.searchName = ''
    this.searchEmail = ''
    this.paginate = {
      page: 1,
      pageSize: 20
    }
    this.users$ = []
    this.getUsers()
  }

}


