import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddSegementsModalComponent } from './add-segements-modal/add-segements-modal.component';
import { UsersSegementsComponent } from './users-segements/users-segements.component';

@NgModule({
  declarations: [
    AddSegementsModalComponent,
    UsersSegementsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [UsersSegementsComponent]
})
export class UsersSegmentsModule { }
