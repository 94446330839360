import { Component, Input, OnInit } from '@angular/core';
import { CreateNotificationService } from '../../create-notification.service';

export type os = 'ios' | 'android'

interface NotificationData {
  title: string,
  subtitle: string,
  message: string,
  image: string,
  url: string,
}


@Component({
  selector: 'app-phone-android',
  templateUrl: './phone-android.component.html',
  styleUrls: ['./phone-android.component.scss']
})
export class PhoneAndroidComponent {

  public notificationData: NotificationData = {
    title: '',
    subtitle: '',
    message: '',
    image: '',
    url: ''
  }

  @Input() system: os = 'android'

  constructor(private notificationS: CreateNotificationService) {
    this.notificationS.refreshNotificationData$.subscribe(v => {      
      this.notificationData = v as NotificationData
    })
  }

  ngOnInit(): void {
  }

}
