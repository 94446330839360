import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { UserDeleteModalComponent } from '../user-delete-modal/user-delete-modal.component';
import { User } from '../users-layout/users-layout.component';
import { UsersService, UserStatistics } from '../users.service';

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}




@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html'
})
export class UserInfoComponent implements OnInit {

  @Output() nextUser = new EventEmitter()

  loading = true;

  @Input() user: any = {}
  ranking: any;
  rankFox: any;
  rankAll: any;

  userStatistcs: UserStatistics | any = {}

  constructor(
    private usersService: UsersService,
    private modalSerivce: NgbModal,
    private toastr: ToastrService
  ) {
  }
  ngOnInit(): void {
    this.getUserInfo()
  }

  getUserInfo() {
    console.log(this.user);
    this.usersService.getUserInfo(this.user._id)
      .pipe(map(r => r.list))
      .subscribe(
        userStatistics => {
          this.userStatistcs = (userStatistics as UserStatistics)
          this.loading = false;
        })

    this.usersService.getUserBestListe()
      .pipe(map(r => r.list))
      .subscribe(
        usersListe => {
          console.log(this.user.email, 'email');
          
          this.ranking = usersListe
          this.getFoxhuntRanking();
          this.getAllTimeRanking();
          console.log(this.rankFox, this.rankAll);
          
        })

  }


  getFoxhuntRanking() {
    this.ranking.sort(dynamicSort("totalPointsLastMonth"))
    this.rankFox = this.ranking.findIndex(obj => obj.email == this.user.email);
  }


  getAllTimeRanking() {
    this.ranking.sort(dynamicSort("totalPoints"))
    this.rankAll = this.ranking.findIndex(obj => obj.email == this.user.email);
  }

  openDeleteUserModal(user: User) {
    const modal = this.modalSerivce.open(UserDeleteModalComponent)
    modal.componentInstance.user = user
    modal.result.then(() => {
      this.toastr.info('Deleting user...')
      this.usersService
        .deleteUser(user._id)
        .subscribe(() => {
          this.toastr.success('User deleted')
          this.nextUser.emit()
        })
    })
  }
}
