<nav class="navbar navbar-expand-md bg-white"><a href="/" class="navbar-brand"> <img src="/assets/logo.png"
            style="height:50px"></a><button type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
        class="navbar-toggler"><span class="navbar-toggler-icon"></span></button>
    <div id="collapsibleNavbar" class="collapse navbar-collapse">
        <!-- ngInclude: '/partials/navigation/view/navbar-right.jade' -->
        <div class="ng-scope">
            <ul class="navbar-nav ng-scope">
                <li class="nav-item"><a [routerLink]="['/dashboard']" class="nav-link">Home </a></li>
                <li class="nav-item"><a (click)="signOut()" class="nav-link pointer">Ausloggen </a></li>
            </ul>
        </div>
    </div>
</nav>