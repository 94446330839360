import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DailyPackages } from './pages/puzzles-publishing/publishing-calendar/publishing-calendar.component';


@Injectable({
  providedIn: 'root'
})
export class DailyPackagesService {

  backendUrl = environment.backendUrl;

  constructor(private http: HttpClient) { }
  
  getConfig(): Observable<DailyPackages[]> {
    return this.http.get<DailyPackages[]>(`${this.backendUrl}/api/dailyPackages`)
  }
  
  getPremiumPackage(): Observable<DailyPackages> {
    return this.http.get<DailyPackages>(`${this.backendUrl}/api/getPremiumDailyPackage`)
  }

  getConfigPromise(): Promise<DailyPackages[]> {
    return this.http.get<DailyPackages[]>(`${this.backendUrl}/api/dailyPackages`).toPromise()
  }
  updateConfig(config: DailyPackages): Observable<DailyPackages> {
    return this.http.put<DailyPackages>(`${this.backendUrl}/api/dailyPackages`, config)
  }
}
