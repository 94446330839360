import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PuzzlesCategoryPipe } from './puzzles-category.pipe';

@NgModule({
  declarations: [PuzzlesCategoryPipe],
  imports: [
    CommonModule
  ],
  exports: [PuzzlesCategoryPipe]
})
export class SharedPipesModule { }
