import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { FormsModule } from '@angular/forms';



import * as AllIcons from '@ant-design/icons-angular/icons';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { Routes } from '@angular/router';
import { IconDefinition } from '@ant-design/icons-angular';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { DailyPackageConfigModule } from '../shared/daily-package-config/daily-package-config/daily-package-config.module';
import { NzSpinModule } from 'ng-zorro-antd/spin';


const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])


const routes: Routes = [
  {
    path: '', component: PageLayoutComponent
  } 
];

@NgModule({
  declarations: [PageLayoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzTableModule,
    NzButtonModule,
    NzInputNumberModule,
    NzCalendarModule,
    NzBadgeModule,
    NzDatePickerModule,
    NzModalModule,
    NzTabsModule,
    NzIconModule,
    NzCardModule,
    NzListModule,
    NzGridModule,
    NzMentionModule,
    NzInputModule,
    DailyPackageConfigModule,
    NzTypographyModule,
    NzSpinModule
  ],
  
  providers: [
   [{ provide: NZ_ICONS, useValue: icons }]
  ]
})
export class PremiumBucketModule { }
