<div class="row">
    <div class="col-12">
        <h1>Papierkorb</h1>
        <small>Toogle Checkbox to select</small>
    </div>
</div>
<div *ngIf="!loading" class="scroll" (scroll)="onScroll($event)">
    <div *ngFor="let puzzle of puzzles; let index = index" class="col-12"
        [ngClass]="{'done': puzzle.status==2, 'open': puzzle.status != 2}" style="border-bottom:1px solid grey">
        <div class="row">
            <div style="display: flex;
            align-self: center;
            width: 35px;
            margin-left: 20px;">
                <input type="checkbox" class="pointer" (click)="pushPuzzle($event, puzzle)" name="" id="">
            </div>
            <div class="col-1">
                <p style="margin-top:15px;">{{index+1}}.</p>
            </div>
            <div class="col-3">
                <p style="margin-top:15px;">{{puzzle.day | date:'dd.MM.yyyy'}}</p>
            </div>
            <div class="col-2">
                <p style="margin-top:15px;">{{puzzle.uuid}}</p>
            </div>
            <div class="col-2">
            </div>
        </div>
    </div>
</div>
<p *ngIf="loading">
    loading...
</p>