import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.css']
})
export class Login2Component implements OnInit {
  validateForm!: FormGroup;

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    // if (!this.validateForm.valid) {
    //   this.toastr.error('Complete form correctly');
    //   return;
    // }
    const credentials = this.validateForm.value;
    // const credentials = {email: '', password: ''};
    this.auth
      .login(credentials)
      .subscribe(
        (user) => {
          this.toastr.success('Complete form correctly');
          this.route.navigate(['/app/publishingList']);
        },
        (err) => {
          console.log(err)
          this.toastr.error('Something went wrong');
        }
      );

  }

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private route: Router,
    private toastr: ToastrService
  ) {
   }
  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });

  }
}
