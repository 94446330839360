<div *ngIf="loading">
    <div class="example">
        <nz-spin [nzSpinning]="loading" nzSimple></nz-spin>
      </div>
</div>
<div *ngIf="!loading" class="table">
    <table (scroll)="onScroll($event)">
        <thead>
            <tr>
                <th>Type</th>
                <th>Message</th>
                <th>Status</th>
                <th>Sent At</th>
                <th>Succes</th>
                <th>Failed</th>
                <th>Clicked ratio</th>
                <!-- <th class="created-by">Created by</th> -->
                <!-- <th>Actions</th> -->
            </tr>
        </thead>
    </table>
    <tbody>
        <tr class="notification-entry" *ngFor="let notification of notifications">
            <td class="notification-type">
                <div class="icon-push" title="Push Notification"></div>
            </td>
            <td class="notification-content">
                <a>
                    {{notification.headings.en}}
                    <!-- {{notification.headings.en | json}} -->
                    <!-- {{notification.contents | json}} -->
                </a>
            </td>
            <td class="notification-status">
                <a href="/apps/d73878bc-6afb-4fbc-93c3-d1e3c5f580d8/notifications/73a815f6-2513-40f5-a6ad-587d302b703d">
                    <div class="delivery-container" >
                        <div class="badge-small badge-delivered text-capitalize">Delivered</div>
                    </div>
                </a>
            </td>
            <td class="submitted date">
                <span class="">{{setDate(notification) | date:'dd.MM.yyyy'}}</span><br>
                <!-- <span class="relative-time">160 days, 13 hours ago</span> -->
            </td>
            <td class="sent-to">
                {{notification.successful}}
            </td>
            <td class="result">
                {{notification.failed}}
            </td>
            <td class="conversion">
                <span>{{notification.converted}}%</span>
            </td>
            <!-- <td class="created-by">
                <div class="circle-container" title="r.mueller-labrenz@stegenwaller.de">
                    <div class="circle profile-img">
                        <img class="profile-img" src="https://www.gravatar.com/avatar/aad318920058337f007c644a0f2d8cac?d=mm">
                    </div>
                </div>
            </td> -->
            <!-- <td class="one action text-center">
                <button class="btn btn-info">View</button>
            </td> -->
        </tr>
    </tbody>
</div>