import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { UsersTableComponent } from './users-table/users-table.component';
import { UsersService } from './users.service';
import { UsersLayoutComponent } from './users-layout/users-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserDeleteModalComponent } from './user-delete-modal/user-delete-modal.component';
import { MemberShipStringPipe } from './member-ship-string.pipe';
import { UserInfoComponent } from './user-info/user-info.component';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [
    UsersTableComponent,
    UsersLayoutComponent,
    UserDeleteModalComponent,
    MemberShipStringPipe,
    UserInfoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NzButtonModule
  ],
  providers: [UsersService, DecimalPipe],
  exports: [UsersLayoutComponent, UsersTableComponent]
})
export class UsersViewModule { }
