<div>
    <div class="phone">
        <div class="sc-ekHBYt gDsQHl phone-background">
            <div class="sc-eTyWNx MYmHo phone-notification-container">
                <div class="sc-ccXozh phone-notification">
                    <div class="sc-bsVVwV hnjSVP phone-notification-heading">
                        <span class="sc-exdmVY phone-notification-heading-title">KREUZWORT-TOTAL</span>
                        <span class="sc-bWjmDF phone-notification-heading-now">now</span>
                    </div>
                    <div class="sc-evWYkj iFxjGE phone-notification-body-container">
                        <div class="sc-hZhUor kOkFzt phone-notification-body-margin">
                            <div class="sc-jMvuUo cuNUrH phone-notification-body">
                                <div class="sc-idjmjb iTvQvE phone-notification-body-title">{{notificationData.title}}
                                </div>
                                <div class="sc-idjmjb iTvQvE phone-notification-body-subtitle">
                                    {{notificationData.subtitle}}</div>
                                <div class="sc-fHlXLc egrsKK phone-notification-body-message">
                                    {{notificationData.message}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>