import { Component, Input, OnInit } from '@angular/core';
import { PuzzlesService } from 'src/app/dashboard/dashboard/puzzle-view/puzzles.service';

@Component({
  selector: 'app-puzzle-preview-pop-over',
  template: `
            <div style="margin-bottom:10px" class="row">
                <div class="col">
                    <div class="grid">
                        <div *ngFor="let questions of puzzle.grid" style="overflow:auto;float:left;" class="row">
                            <div class="col">
                                <div *ngFor="let question of questions"
                                    style="padding:5px;float:left; height:40px;width:40px; border: 1px solid black;position:relative;display:flex;justify-content:center;align-questions:center;text-align:center"
                                    [ngClass]="{'active': question.question_num==question_num}" class="grid">
                                    {{question.answer}}<span
                                        style="position:absolute;top:0px;left:0px;height:5px;width:5px;font-size:10px"
                                        *ngIf="question.question_num!=null">{{question.question_num}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

  `,
  styles: [`
  
  
  .row {
        width: 500px;
     }
  
  
  
  
  `]
})
export class PuzzlePreviewPopOverComponent implements OnInit {

  puzzle: any = {}

  @Input() set puzzleId(id: string) {
    this.puzzleService.getSinglePuzzle(id)
      .subscribe(
        (puzzle) => {
          console.log(puzzle)
          this.puzzle = puzzle
        })
  }

  @Input() question_num: any = 0;



  constructor(
    private puzzleService: PuzzlesService,
  ) { }
  ngOnInit(): void {

  }



}
