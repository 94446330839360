import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PaginateData } from '../../users-view/users-table/users-table.component';
import { Notification } from '../notifications-view/notifications-view.component';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-notifications-table',
  templateUrl: './notfications-table.component.html',
  styleUrls: ['./notfications-table.component.scss']
})
export class NotficationsTableComponent implements OnInit {

  searchEmail = ''
  searchName = ''

  paginate: PaginateData = {
    page: 1,
    pageSize: 50
  }
  loading = true;

  notifications: Notification[] = []

  onScroll(event: any) {

    const scrolledBottom = event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight

    if (scrolledBottom) {
      this.paginate.page++;
      this.getNotifications()
    }
  }

  constructor(
    private modalSerivce: NgbModal,
    private notificationsService: NotificationsService,
    private toastr: ToastrService) {
    this.getNotifications()
  }

  getNotifications() {
    this.notificationsService
      .getAllNotifications(this.paginate)
      // .pipe(
      // map(res => (res.list as User[])),
      // map(users => {
      //   return users.map(user => {
      //     const timestamp = user._id.toString().substring(0, 8)
      //     return {
      //       ...user,
      //       date: new Date(parseInt(timestamp, 16) * 1000),
      //     }
      //   })
      // })
      // )
      .subscribe(notificationData => {
        this.loading = false;
        console.log(notificationData.total_count);
        
        this.notifications = [...this.notifications, ...notificationData.notifications];
        console.log(this.notifications, 'notifications');
        // this.users$ = [...this.users$, ...notificationData]
      })
  }

  reset() {
    this.searchName = ''
    this.searchEmail = ''
    this.paginate = {
      page: 1,
      pageSize: 20
    }
    this.getNotifications()
  }

  ngOnInit(): void {
    console.log(this.notifications, 'er');

  }

  setDate(notification: Notification): Date {
    return new Date(notification.queued_at * 1000)
  }

}
