import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BetaTestersListComponent } from './betatesters-list/betatesters-list.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { CommonModule } from '@angular/common';
import { TablePuzzleComponent } from './dashboard/puzzle-view/table-puzzle/table-puzzle.component';
import { PuzzlesService } from './dashboard/puzzle-view/puzzles.service';
import { PuzzleViewModule } from './dashboard/puzzle-view/puzzle-view.module';
import { UsersViewModule } from './dashboard/users-view/users-view.module';
import { PremiumViewModule } from './dashboard/premium-view/premium-view.module';
import { BinPuzzleViewModule } from './dashboard/bin-puzzle-view/bin-puzzle-view.module';
import { PuzzleSearchViewModule } from './dashboard/puzzle-search-view/puzzle-search-view.module';
import { StatisticsViewModule } from './dashboard/statistics-view/statistics-view.module';
import { NotificationsViewModule } from './dashboard/notifications-view/notifications-view.module';
import { CreateNotificationViewModule } from './create-notification-view/create-notification-view.module';

const routes: Routes = [
  {
    path: 'dashboard', component: DashboardComponent,
    children: [
      {
        path: '',
        component: TablePuzzleComponent
      }
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PuzzleViewModule,
    UsersViewModule,
    PremiumViewModule,
    BinPuzzleViewModule,
    PuzzleSearchViewModule,
    StatisticsViewModule,
    NotificationsViewModule,
    CreateNotificationViewModule,
    RouterModule.forRoot(routes)],

  providers: [
    PuzzlesService
  ],
  declarations: [DashboardComponent, BetaTestersListComponent, NavBarComponent],
  exports: [RouterModule, DashboardComponent, PuzzleViewModule]
})
export class DashboardModule { }
